import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Badge, Button, Col, Form, Row,
} from 'react-bootstrap';
import Table from 'components/Table';
import TextField from 'components/Form/TextField';
import { CellProps } from 'react-table';
import { DTable } from 'types/table';
import iconDownload from 'assets/img/icons/download.svg';
import { formatPhoneNumber } from 'utils/formats';
import { useNavigate } from 'react-router-dom';
import { IoIosAddCircleOutline } from 'react-icons/io';
import {
  MODAL_ACTIVE_ADMIN, MODAL_ACTIVE_USER, MODAL_ADD_USER, MODAL_DISABLED_ADMIN, MODAL_DISABLED_USER,
} from 'constants/utils';
import _ from 'lodash';
import classnames from 'classnames';

function Investors( {
  users, fetchData, isLoading, type, onOpenCreateUserModal,
  openCreateUserAdminModal,
  downloadDocument,
}:{
  onOpenCreateUserModal: ( params:any )=>void
  openCreateUserAdminModal: ( params:any )=>void
  type?:string;
  isLoading?:boolean;
  users:DTable;
  downloadDocument: ( params:any )=>void ;
  fetchData: ( params:any, pageSize:number )=>void ; } ) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [criteria, setCriteria] = useState( '' );

  const debounceSearch = useCallback( _.debounce( ( fn ) => fn(), 500 ), [] );

  const headers = [
    {
      Header: t( 'admin.investor.header1' ),
      headerClassName: 'text--left',
      accessor: 'firstName',
      className: 'text--bold',
      Cell: ( { cell }: CellProps<any> ) => (
        <span
          className="text--bold cursor-pointer"
          onClick={() => {
            const id = cell.row.original.id || cell.row.original.contacId;
            navigate(
              `/admin/investors/${id}/accounts/user`,
              {
                state: {
                  fullName: `${cell.row.original.firstName} ${cell.row.original.lastName}`,
                  credentialState: cell.row.original.credentialState,
                  extraData: { email: cell.row.original.email },
                },
              },
            );
          }}
        >
          {`${cell.value} ${cell.row.original.lastName}`}
        </span>
      ),
    },
    {
      Header: t( 'admin.investor.header2' ),
      headerClassName: 'text--left',
      accessor: 'email',
      customWidth: 300,
    },
    {
      Header: t( 'admin.investor.header3' ),
      headerClassName: 'text--left',
      accessor: 'mobile',
      customWidth: 150,
      Cell: ( { cell }: CellProps<any> ) => <span className="text--bold">{formatPhoneNumber( cell.value )}</span>,
    },
    {
      Header: t( 'admin.investor.header4' ),
      headerClassName: 'text--left',
      accessor: 'dninie',
      customWidth: 150,
    },
  ];
  if ( type !== 'inv' && type !== 'pro' ) {
    headers.push( {
      headerClassName: 'text--left',
      Header: t( 'admin.investor.header5' ),
      accessor: 'estado',
      customWidth: 100,
      Cell: ( { cell }: CellProps<any> ) => (
        <Button
          variant="link"
          className={classnames( 'p-0', {
            'cursor-no-pointer': cell.row.original.credentialState !== 1,
            'cursor-pointer': cell.row.original.credentialState === 1
                  && ( cell.value === 0 || cell.value === 1 ),
          } )}
          onClick={() => {
            if ( cell.row.original.credentialState === 1
                    && ( cell.value === 0 || cell.value === 1 ) ) {
              onOpenCreateUserModal( {
                id: cell.row.original.id,
                fullName: `${cell.row.original.firstName} ${cell.row.original.lastName}`,
                action: cell.value === 0 ? MODAL_ACTIVE_USER : MODAL_DISABLED_USER,
              } );
            }
          }}
        >
          <span
            className="text--bold"
          >
            <Form.Check
              type="switch"
              disabled={cell.row.original.credentialState !== 1}
              defaultChecked={cell.row.original.credentialState === 1 && cell.value === 0}
            />
          </span>
        </Button>
      ),
    } );
    // @ts-ignore
    headers.push( {
      Header: t( 'admin.investor.header6' ),
      headerClassName: 'text--left',
      accessor: 'credentialState',
      customWidth: 150,
      Cell: ( { cell }: CellProps<any> ) => {
        let bg = 'primary';
        let text = 'AGREGAR';
        if ( cell.value === 1 ) {
          bg = 'light';
          text = 'CREADO';
        }
        if ( cell.value === 2 ) {
          bg = 'danger';
          text = 'DESCARTADO';
        }

        return (
          <Badge
            bg={bg}
            className={cell.value !== 1 ? 'cursor-pointer' : ''}
            onClick={() => {
              if ( cell.value === 0 || cell.value === 2 ) {
                onOpenCreateUserModal( {
                  id: cell.row.original.id,
                  fullName: `${cell.row.original.firstName} ${cell.row.original.lastName}`,
                  action: MODAL_ADD_USER,
                } );
              }
            }}
          >
            {cell.value === 0 && (
            <IoIosAddCircleOutline
              size={20}
              style={{ marginRight: '5px' }}
            />
            )}
            {text}
          </Badge>
        );
      },
    } );

    headers.push( {
      headerClassName: 'text--left',
      Header: t( 'admin.investor.header51' ),
      accessor: 'role',
      customWidth: 100,
      Cell: ( { cell }: CellProps<any> ) => (
        <Button
          variant="link"
          className={classnames( 'p-0', {
            'cursor-no-pointer': !( cell.value === 0 || cell.value === 1 ),
            'cursor-pointer': ( cell.value === 0 || cell.value === 1 ),
          } )}
          onClick={() => {
            if ( cell.value === 0 || cell.value === 1 ) {
              openCreateUserAdminModal( {
                id: cell.row.original.id,
                userId: cell.row.original.userId,
                fullName: `${cell.row.original.firstName} ${cell.row.original.lastName}`,
                action: cell.value === 0 ? MODAL_DISABLED_ADMIN : MODAL_ACTIVE_ADMIN,
              } );
            }
          }}
        >
          <span
            className="text--bold"
          >
            <Form.Check
              type="switch"
              className="switch-admin"
              disabled={!( cell.value === 0 || cell.value === 1 )}
              defaultChecked={cell.value === 0}
            />
          </span>
        </Button>
      ),
    } );
  }
  let defaultSortBy = [{ id: 'firstName', desc: true }];
  if ( type === 'inv' ) {
    defaultSortBy = [{ id: 'firstName', desc: true }];
  }
  if ( type === 'pro' ) {
    defaultSortBy = [{ id: 'firstName', desc: true }];
  }

  return (
    <Table
      columns={[...headers]}
      isLoading={isLoading}
      data={users.data}
      total={users.totalItems}
      pageCount={users.pageCount}
      fetchData={fetchData}
      defaultSortBy={defaultSortBy}
      header={( filters, setFilters ) => (
        <Row className="align-items-center">
          <Col lg={9} sm={8} xs={6}>
            <Button
              size="sm"
              className="pt-2 pb-2 ps-4 pe-4 text--normal btn--rounded d-flex align-items-center"
              onClick={() => downloadDocument( { download: true } )}
            >
              <div>
                <img src={iconDownload} alt="" className="icon--img" />
                {t( 'admin.common.download' )}
              </div>
            </Button>
          </Col>
          <Col lg={3} sm={4} xs={6}>
            <TextField
              value={criteria}
              showErrors={false}
              placeholder="search"
              appendIcon="icon-search text-primary"
              onChange={( value ) => {
                setCriteria( value );
                debounceSearch( () => setFilters( ['firstName', 'lastName'], value ) );
              }}
              classNames={{ formGroup: 'input-filters' }}
            />
          </Col>
        </Row>
      )}
    />
  );
}

export default Investors;
