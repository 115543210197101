import { setIn } from 'final-form';

const validateFormValues = ( schema: any ) => async ( values: any ) => {
  if ( typeof schema === 'function' ) {
    // eslint-disable-next-line no-param-reassign
    schema = schema();
  }
  try {
    await schema.validate( values, { abortEarly: false } );
  } catch ( err ) {
    return err.inner.reduce( ( formError: any, innerError: any ) => setIn( formError, innerError.path || '', innerError.message ), {} );
  }
};
export default validateFormValues;
