import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import ReactPlayer from 'react-player';
import { Carousel } from 'react-responsive-carousel';
import _ from 'lodash';
import { getImagePreview } from 'utils/chars';

type VideoModalProps = {
  photographicAdvance: any ;
  show: boolean,
  selectedIndex: number,
  selectedKey: number,
  onClose: () => void,
};
type SlideProps = {
  url: string;
  preview: any;
  isSelected?: boolean
};

function Slide( { url, preview, isSelected }: SlideProps ) {
  const [playing, setPlaying] = useState( false );

  useEffect( () => {
    if ( !isSelected ) setPlaying( false );
  }, [isSelected] );

  return (
    playing && isSelected
      ? (
        <ReactPlayer
          width="100%"
          height="500px"
          url={url}
          playing={playing}
          controls
        />
      )
      : (
        <>
          <img src={preview} style={{ height: 490, objectFit: 'cover' }} alt="..." className="w-100 img-fluid" />
          <Button variant="link" onClick={() => setPlaying( true )} className="carousel-slider--play">
            <i className="icon-play" />
          </Button>
        </>
      )
  );
}

function VideoModal( {
  show, onClose, photographicAdvance, selectedKey, selectedIndex,
}: VideoModalProps ) {
  const [keySlide, setKeySlide] = useState( selectedIndex );
  const customRenderItem = ( item: any, props: any ) => <item.type {...item.props} {...props} />;
  const currentMedia = _.get( photographicAdvance, `[${keySlide}]`, null );

  useEffect( () => {
    setKeySlide( selectedIndex );
  }, [selectedIndex] );

  return (
    <Modal
      show={show}
      size="lg"
      className="modal-carousel"
      centered
      onHide={onClose}
    >
      <Modal.Header closeButton>
        <div className="text-center w-100">
          <h3 className="text-center">{selectedKey}</h3>
          <p className="text--xmd text--gray8 mb-0">{currentMedia?.nombre}</p>
        </div>
      </Modal.Header>
      <Modal.Body className="p-0">
        <Carousel
          showThumbs={false}
          showStatus={false}
          showIndicators={false}
          renderItem={customRenderItem}
          onChange={( val ) => {
            setKeySlide( val );
          }}
          renderArrowPrev={( onClick, showArrow ) => (
            showArrow
              ? (
                <Button onClick={onClick} variant="link" className="carousel-slider--left-arrow">
                  <i className="icon-arrow-left" />
                </Button>
              ) : null
          )}
          renderArrowNext={( onClick, showArrow ) => (
            showArrow
              ? (
                <Button onClick={onClick} variant="link" className="carousel-slider--right-arrow">
                  <i className="icon-arrow-right" />
                </Button>
              ) : null
          )}
        >
          {!!photographicAdvance
          && photographicAdvance.length > 0
          && photographicAdvance.map( ( item: { urlVideo: any; base64: any; nombre: any; } ) => {
            if ( item.urlVideo ) {
              const img0 = getImagePreview( item.urlVideo );
              return (
                <Slide
                  key={item.nombre}
                  url={item.urlVideo}
                  preview={img0}
                />
              );
            }
            if ( item.base64 ) {
              return (
                <img
                  key={item.nombre}
                  src={item.base64}
                  alt="..."
                  style={{ height: 490, objectFit: 'cover' }}
                  className="w-100 img-fluid"
                />
              );
            }
            return null;
          } )}
        </Carousel>
      </Modal.Body>
    </Modal>
  );
}

export default VideoModal;
