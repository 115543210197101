import * as yup from 'yup';
import { Message } from 'yup/lib/types';

const validPassword = ( t: ( arg0: string ) => Message<{}> ) => yup
  .string()
  .required()
  .test( 'minimum_letter', t( 'common.password_minimum_letter' ), ( password?: string ) => !password || password.length >= 8 )
  .test( 'minimum_special', t( 'common.password_minimum_special' ), ( password?: string ) => !password || /[!@#$%^&*+-.]/.test( password ) )
  .test( 'minimum_number', t( 'common.password_minimum_number' ), ( password?: string ) => !password || /(\D*\d){2,}/.test( password ) )
  .test( 'minimum_uppercase', t( 'common.password_minimum_uppercase' ), ( password?: string ) => !password || /[A-Z]/.test( password ) )
  .test( 'minimum_lowercase', t( 'common.password_minimum_lowercase' ), ( password?: string ) => !password || /[a-z]/.test( password ) );

export const yupChangePassword = ( t: any ) => {
  const schemaObj: any = {};
  schemaObj.currentPassword = yup.string().required();
  schemaObj.password = validPassword( t );
  schemaObj.confirmPassword = yup
    .mixed()
    .required( )
    .oneOf( [yup.ref( 'password' )], t( 'common.equal_password' ) );

  return schemaObj;
};

export const yupForcePassword = ( t: any ) => {
  const schemaObj: any = {};
  schemaObj.password = validPassword( t );
  schemaObj.confirmPassword = yup
    .mixed()
    .required( )
    .oneOf( [yup.ref( 'password' )], t( 'common.equal_password' ) );

  return schemaObj;
};

export const yupChangeOldPassword = ( t: any ) => {
  const schemaObj: any = {};
  schemaObj.password = validPassword( t );
  schemaObj.confirmPassword = yup
    .mixed()
    .required( )
    .oneOf( [yup.ref( 'password' )], t( 'common.equal_password' ) );

  return schemaObj;
};

export const yupEditData = () => {
  const schemaObj: any = { fullName: null, email: null };
  // @ts-ignore
  schemaObj.fullName = validName();
  // @ts-ignore
  schemaObj.email = validEmail();
  return { schemaObj };
};
