import React, { useCallback, useContext, useState } from 'react';
import InvestorsView from 'views/Admin/Investors';
import useFetchTableData from 'hooks/useFetchTableData';
import ProfileService from 'services/Profile';
import AdminContainer from 'components/Admin/AdminContainer';
import { useLocation, useParams } from 'react-router-dom';
import CreateUserModal from 'components/Modal/CreateUserModal';
import { LoadingContext } from 'context/Loading';
import handlePromise from 'utils/Promise';
import { getFile } from 'utils/downloadBase64';
import CreateUserAdminModal from '../../../components/Modal/CreateUserAdminModal';

function Investors() {
  const { id, type } = useParams();
  const location = useLocation();
  const dataLocation:any = location?.state;
  const { toggleLoading } = useContext( LoadingContext );

  const [dataReloaded, setDataReloaded] = useState( new Date().valueOf() );
  const { fetchData, data, dataLoading } = useFetchTableData( {
    promise: ( params ) => {
      if ( type === 'inv' && id ) {
        return ProfileService.getAllByCuentaDeInversionBackOffice(
          { ...params, cuentaDeInversionId: id },
        );
      }
      if ( type === 'pro' && id ) {
        return ProfileService.getContactoAllBackOffice( { ...params, promocionId: id } );
      }
      return ProfileService.getContactoAllBackOffice( params );
    },
    deps: [dataReloaded],
  } );

  const [showCreateUserModal, setCreateUserModal] = useState( false );
  const [showCreateUserAdminModal, setCreateUserAdminModal] = useState( false );
  const [contact, setContact] = useState<
  { id:number; fullName:string; action:string; userId:any }>( {
    id: 0, fullName: '', action: '', userId: 0,
  } );

  const openCreateUserModal = useCallback( ( params:{
    id:number; fullName:string; action:string; userId:any } ) => {
    setContact( params );
    setCreateUserModal( ( state ) => !state );
  }, [] );

  const openCreateUserAdminModal = useCallback( ( params:{
    id:number; fullName:string; action:string; userId:any } ) => {
    setContact( params );
    setCreateUserAdminModal( ( state ) => !state );
  }, [] );

  const closeCreateUserModal = useCallback( ( ) => {
    setCreateUserModal( false );
  }, [] );
  const closeCreateUserAdminModal = useCallback( ( ) => {
    setCreateUserAdminModal( false );
  }, [] );

  let headerTitle = type === 'inv' ? dataLocation?.nameCuentadeInversion : '';
  headerTitle = type === 'user' ? dataLocation?.fullName : headerTitle;
  headerTitle = type === 'pro' ? dataLocation?.codigoPromocion : headerTitle;

  const downloadDocument = useCallback( async ( params: any ) => {
    toggleLoading( true );
    let downloadDocumentData;
    if ( type === 'inv' && id ) {
      [, , downloadDocumentData] = await handlePromise(
        ProfileService.getAllByCuentaDeInversionBackOffice( { ...params, cuentaDeInversionId: id }, { responseType: 'blob' } ),
      );
    } else if ( type === 'pro' && id ) {
      [, , downloadDocumentData] = await handlePromise(
        ProfileService.getContactoAllBackOffice( { ...params, promocionId: id }, { responseType: 'blob' } ),
      );
    } else {
      [, , downloadDocumentData] = await handlePromise(
        ProfileService.getContactoAllBackOffice( { ...params }, { responseType: 'blob' } ),
      );
    }
    setTimeout( () => {
      toggleLoading( false );
    }, 900 );

    getFile( downloadDocumentData, 'inversores' );
  }, [] );

  return (
    <AdminContainer
      headerTitle={headerTitle}
      type={type}
      id={id ? Number( id ) : undefined}
    >
      <InvestorsView
        onOpenCreateUserModal={openCreateUserModal}
        openCreateUserAdminModal={openCreateUserAdminModal}
        type={type}
        users={data}
        fetchData={fetchData}
        isLoading={dataLoading}
        downloadDocument={downloadDocument}
      />
      <CreateUserModal
        show={showCreateUserModal}
        onClose={closeCreateUserModal}
        contact={contact}
        reloadData={() => setDataReloaded( new Date().valueOf() )}
      />
      <CreateUserAdminModal
        show={showCreateUserAdminModal}
        onClose={closeCreateUserAdminModal}
        contact={contact}
        reloadData={() => setDataReloaded( new Date().valueOf() )}
      />
    </AdminContainer>
  );
}

export default Investors;
