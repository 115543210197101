import React, { useEffect, useState } from 'react';
import {
  Button, Col, Modal, Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import validateForm from 'utils/Validator';
import ProfileService from 'services/Profile';
import useSubmit from 'hooks/useSubmit';
import Form from 'components/Form/Layout';
import PasswordInput from 'components/PasswordInput';
import { yupChangePassword } from 'utils/yupFields';
import SuccessMessage from '../SuccessMessage';

type ChangePasswordModalProps = {
  show: boolean,
  onClose: () => void,
};

function ChangePasswordModal( { show, onClose }: ChangePasswordModalProps ) {
  const { t } = useTranslation();
  const [formSubmitted, setFormSubmitted] = useState( false );
  const validationSchema = yup.object( yupChangePassword( t ) );
  const validate = validateForm( validationSchema );

  const submitForm = useSubmit( {
    promise: ( formData ) => ProfileService.putChangePassword( formData ),
    callback: () => setFormSubmitted( true ),
    showAlert: false,
  } );

  useEffect( () => {
    if ( !show ) setFormSubmitted( false );
  }, [show] );

  return (
    <Modal
      show={show}
      className="modal-carousel"
      centered
      onHide={onClose}
    >
      <Modal.Header className="pb-0" closeButton />
      <Modal.Body className="pt-0">
        <Row>
          <Col md={10} className="m-auto">
            <h3 className="text-center mb-4">{t( 'profile.changePassword' )}</h3>
            {formSubmitted
              ? (
                <SuccessMessage description={t( 'profile.changePassSuccess' )} onClose={onClose} />
              )
              : (
                <>
                  <div className="info-card--light">
                    <p className="text--xs m-0 text--bold">
                      {t( 'recoverPassword.requirementsTitle' )}
                    </p>
                    <p className="text--xs m-0">
                      <ul className="m-0">
                        <li>{t( 'recoverPassword.requirements1' )}</li>
                        <li>{t( 'recoverPassword.requirements2' )}</li>
                        <li>{t( 'recoverPassword.requirements3' )}</li>
                        <li>{t( 'recoverPassword.requirements4' )}</li>
                        <li>{t( 'recoverPassword.requirements5' )}</li>
                      </ul>
                    </p>
                  </div>
                  <Form
                    validate={validate}
                    onSubmit={submitForm}
                    body={(
                      <div className="pt-4">
                        <Row className="mt-4">
                          <Col>
                            <PasswordInput
                              field="currentPassword"
                              label="profile.currentPassword"
                              classNames={{ formGroup: 'mb-2' }}
                            />
                          </Col>
                        </Row>
                        <Row className="mt-4">
                          <Col>
                            <PasswordInput
                              field="password"
                              label="profile.password"
                              classNames={{ formGroup: 'mb-2' }}
                            />
                          </Col>
                        </Row>
                        <Row className="mt-4">
                          <Col>
                            <PasswordInput
                              field="confirmPassword"
                              label="profile.confirmPassword"
                              classNames={{ formGroup: 'mb-2' }}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col className="mt-4">
                            <Button
                              variant="primary"
                              type="submit"
                              className="btn--full-width"
                            >
                              {t( 'confirm' )}
                            </Button>
                          </Col>
                        </Row>
                      </div>
                  )}
                  />
                </>
              )}
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default ChangePasswordModal;
