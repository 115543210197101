import React from 'react';
import {
  Row, Col, Button,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import LightTile from 'components/LightTile';
import BodyContent from 'components/BodyContent';
import icon3 from 'assets/img/project-icon1.svg';
import icon6 from 'assets/img/project-icon4.svg';
import icon9 from 'assets/img/project-icon7.svg';
import icon10 from 'assets/img/project-icon8.svg';
import icon17 from 'assets/img/ROI_estDesp_white.svg';

import { KpiResumeProject } from 'types/Project';
import numeral from 'numeral';
import CharDoughnut from 'components/Char/CharDoughnut';
import { formatPercent, formatPercentOneDecimal } from 'utils/formats';
import CharProgress from 'components/Char/CharProgress';
import Documents from 'components/Dashboard/Documents';
import PhotographicAdvance from 'components/Dashboard/PhotographicAdvance';
import MillionNumeral from 'components/MillionNumeral';
import { useNavigate } from 'react-router-dom';
import { FUNDS } from 'constants/routes';
import { convertToSlug } from 'utils/utils';
import icon19 from '../../../assets/img/project-icon13.svg';
import icon20 from '../../../assets/img/project-icon15.svg';
import icon21 from '../../../assets/img/project-icon16.svg';
import icon22 from '../../../assets/img/project-icon17.svg';
import icon23 from '../../../assets/img/project-icon18.svg';

type ProjectProps = {
  onOpenVideoModal: ( param: any, key: any ) => void;
  getAvanceFotografico: ( key: any ) => void;
  downloadFile: ( ) => void;
  downloadFilePdf: ( ) => void;
  kpiProject: KpiResumeProject | null ;
  projectData: KpiResumeProject | null ;
  fundData?: any ;
  investmentStructure: any[] | undefined ;
  totalIncome: any[] | undefined ;
  documents: any[] | undefined ;
  periods: any[] ;
  photographicAdvance: [] | undefined;
  workProgress: { conceptosAvanceObra:any[] | undefined; avanceGeneral:number } ;
};

function FoundProject( {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onOpenVideoModal, kpiProject, totalIncome, investmentStructure, workProgress, documents, periods,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  photographicAdvance, getAvanceFotografico, downloadFile, downloadFilePdf, projectData, fundData,
}:ProjectProps ) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <BodyContent
      showTitleInTablet
      title={(
        <>
          <div className="mb-1">
            <Button
              variant="link"
              size="sm"
              className="text--medium p-0 link-underline"
              onClick={() => {
                navigate( `${FUNDS}/${fundData?.id}/${convertToSlug( fundData?.codigoPromocion )}` );
              }}
            >
              <i className="icon-arrow-left text--green1" />
              Volver a
              {' '}
              {fundData?.codigoPromocion}
            </Button>
          </div>
          <div className="px-3 pt-3 pb-4 p-md-0 pb-md-4">
            <Row className="d-flex justify-content-md-between">
              <Col md={6}>
                <h1 className="mb-2">
                  {projectData?.codigoPromocion}
                </h1>
              </Col>

            </Row>
            {!!projectData?.descripcion && (
              <p className="mb-0" dangerouslySetInnerHTML={{ __html: `${projectData?.descripcion?.toString().replaceAll( '\n', '<br />' )}` }} />
            )}
          </div>
        </>
      )}
    >
      <h3 className="text--lg mb-4">{t( 'projects.projectOverview' )}</h3>

      <div className="row align-items-stretch row-cols-xl-5 row-cols-md-3 row-cols-2 g-3 g-md-4 mb-4">
        <LightTile
          icon={icon3}
          title={( <MillionNumeral number={kpiProject?.capitalTotal} /> )}
          subtitle={t( 'Equity' )}
        />
        <LightTile
          icon={icon19}
          title={numeral( kpiProject?.roiEstimado
            ? Number( kpiProject?.roiEstimado ) / 100 : 0 ).format( formatPercentOneDecimal )}
          subtitle={t( 'Project ROE' )}
        />
        <LightTile
          icon={icon6}
          title={numeral( kpiProject?.tirEstimado
            ? Number( kpiProject?.tirEstimado ) / 100 : 0 ).format( formatPercentOneDecimal )}
          subtitle={t( 'Project IRR' )}
        />
        <LightTile
          icon={icon9}
          title={`${kpiProject?.plazoInversion
            && kpiProject?.plazoInversion > 0 ? Number( kpiProject?.plazoInversion ) / 12 : ''} ${kpiProject?.plazoInversion ? t( 'años' ) : ''}`}
          subtitle={t( 'Holding period' )}
        />

        <LightTile
          icon={icon17}
          title={`${kpiProject?.trimFin ? kpiProject?.trimFin?.toString().toUpperCase() : ''} `}
          subtitle={t( 'End quarter' )}
        />
        <LightTile
          icon={icon10}
          title={numeral( kpiProject?.ltv ? Number( kpiProject?.ltv ) / 100
            : 0 ).format( formatPercent )}
          subtitle={t( 'LTV' )}
        />

        <LightTile
          icon={icon20}
          title={numeral( kpiProject?.rentabilidadSobreCostes
            ? Number( kpiProject?.rentabilidadSobreCostes ) / 100 : 0 ).format( formatPercent )}
          subtitle={t( 'Entry yield' )}
        />
        <LightTile
          icon={icon21}
          title={numeral( kpiProject?.rentabilidadDeSalida
            ? Number( kpiProject?.rentabilidadDeSalida ) / 100 : 0 ).format( formatPercent )}
          subtitle={t( 'Exit Yield' )}
        />

        <LightTile
          icon={icon22}
          title={`${kpiProject?.plazoDeExplotacion ? kpiProject?.plazoDeExplotacion : ''} ${kpiProject?.plazoDeExplotacion ? t( 'meses' ) : ''}`}
          subtitle={t( 'Operating period' )}
        />
        {kpiProject?.noiTotal
        && (
        <LightTile
          icon={icon23}
          title={( <MillionNumeral number={kpiProject?.noiTotal} /> )}
          subtitle={t( 'NOI' )}
        />
        )}
        {kpiProject?.nri
        && (
        <LightTile
          icon={icon23}
          title={( <MillionNumeral number={kpiProject?.nri} /> )}
          subtitle={t( 'Net Rental Income' )}
        />
        )}
      </div>

      <Row>
        <Col xs={{ span: 12, order: 1 }} md={{ span: 6, order: 1 }} xl={{ span: 4, order: 1 }}>
          <CharProgress
            itemKey="myChart3"
            dataObjs={workProgress?.conceptosAvanceObra}
            percent={workProgress?.avanceGeneral}
            title={t( 'Avance de proyecto' )}
            label="nombre"
            val="porcentaje"
            formatVal={formatPercentOneDecimal}
            headers={[true, true, '%']}
          />
        </Col>
        <Col xs={{ span: 12, order: 2 }} className="mt-4 mt-md-0" md={{ span: 6, order: 2 }} xl={{ span: 4, order: 2 }}>
          <CharDoughnut
            itemKey="myChart21"
            dataObjs={totalIncome}
            title={t( 'Ingresos totales' )}
            label="name"
            val="percent"
            formatVal={formatPercentOneDecimal}
            headers={[' ', t( 'nombre' ), false, '%']}
            showHeader={false}
            indicators={false}
            widthValue="70px"
            value="value"
          />
        </Col>
        <Col xs={{ span: 12, order: 3 }} className="mt-4 mt-xl-0" md={{ span: 6, order: 3 }} xl={{ span: 4, order: 3 }}>
          <CharDoughnut
            itemKey="myChart2"
            dataObjs={investmentStructure}
            title={t( 'Estructura de la inversión' )}
            label="name"
            val="percent"
            formatVal={formatPercentOneDecimal}
            headers={[' ', t( 'nombre' ), false, '%']}
            showHeader={false}
            indicators={false}
            widthValue="70px"
            value="value"
          />
        </Col>

        <Col xs={{ span: 12, order: 5 }} md={{ span: 12, order: 5 }} xl={{ span: 8, order: 4 }} className="mt-4">
          <Documents dataObjs={documents} />
        </Col>

        {!!periods.length
        && periods.length > 0 && (
        <Col xs={{ span: 12, order: 4 }} className="mt-4" md={{ span: 6, order: 4 }} xl={{ span: 4, order: 5 }}>
          <PhotographicAdvance
            dataObjs={periods}
            onOpenVideoModal={onOpenVideoModal}
            photographicAdvance={photographicAdvance}
            getAvanceFotografico={getAvanceFotografico}
          />
        </Col>
        )}
      </Row>

    </BodyContent>
  );
}

export default FoundProject;
