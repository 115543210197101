import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Carousel, Table } from 'react-bootstrap';
import { HiOutlineDownload } from 'react-icons/hi';
import moment from 'moment';
import handlePromise from 'utils/Promise';
import ProjectService from 'services/Projects';
import { getFileFromBase64 } from 'utils/downloadBase64';
import { LoadingContext } from 'context/Loading';
import _ from 'lodash';
import NoDataText from 'components/NoDataText';
import CardLight from '../../CardLight';

type LastReportsProps = {
  dataObjs: any[] | undefined ;
};
function LastReports( {
  dataObjs,
}: LastReportsProps ) {
  const { t } = useTranslation();
  const [indexSlide, setIndexSlide] = useState( 0 );
  const [pages, setPages] = useState( 0 );
  const handleSelect = ( selectedIndex: React.SetStateAction<number> ) => {
    setIndexSlide( selectedIndex );
  };
  const { toggleLoading } = useContext( LoadingContext );

  const downloadDocument = useCallback( async ( path: any, name: string ) => {
    toggleLoading( true );
    const [, , downloadDocumentData] = await handlePromise(
      ProjectService.getDownloadFile( { path } ),
    );
    setTimeout( () => {
      toggleLoading( false );
    }, 800 );
    if ( downloadDocumentData ) {
      getFileFromBase64( downloadDocumentData, name );
    }
  }, [] );

  useEffect( () => {
    if ( !!dataObjs && dataObjs.length ) {
      setPages( Math.ceil( dataObjs.length / 6 ) );
      setIndexSlide( 0 );
    }
  }, [dataObjs] );

  return (
    <CardLight title={t( 'dashboard.latestFinancialReports' )} isLoading={!dataObjs}>
      {( !!dataObjs && !dataObjs.length ) && <NoDataText text={t( 'dashboard.noReportText1' )} /> }
      {( !!dataObjs && dataObjs.length ) ? (
        <Carousel
          activeIndex={indexSlide}
          onSelect={handleSelect}
          controls={false}
          touch
          variant="dark"
          interval={20000}
          className="carousel-dash"
          indicators={dataObjs.length > 6}
        >
          {!!pages && _.map( [...Array( pages )], ( pageContent, pageIndex ) => (
            <Carousel.Item
              style={{ minHeight: '430px' }}
              key={pageIndex}
            >
              <Table borderless className="table--info mb-4">
                <thead>
                  <tr>
                    <th>
                      {t( 'name' )}
                    </th>
                    <th className="text--right">
                      {' '}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {!!dataObjs.length && dataObjs.map( ( item: any, index ) => {
                    if ( index >= pageIndex * 6 && index <= ( pageIndex * 6 ) + 5 ) {
                      return (
                        <tr key={item.nombre}>
                          <td>
                            <div className="text--medium">{item.nombre}</div>
                            <div
                              className="text--sm text--gray8"
                            >
                              {item.fecha ? moment( item.fecha ).format( 'DD-MM-YYYY' ) : ''}
                            </div>
                          </td>
                          <td className="text--right">
                            <Button
                              variant="link"
                              size="sm"
                              className="text--medium p-0"
                              onClick={async () => {
                                await downloadDocument( item.url, item.nombre );
                              }}
                            >
                              <HiOutlineDownload color="#166B8E" size={22} />
                            </Button>
                          </td>
                        </tr>
                      );
                    }
                    return null;
                  } )}
                </tbody>
              </Table>
            </Carousel.Item>
          ) )}
        </Carousel>
      ) : null }

    </CardLight>
  );
}

export default LastReports;
