import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import ProfileView from 'views/User/Profile';
import ContactModal from 'components/Modal/ContactModal';
import _ from 'lodash';
import handlePromise from 'utils/Promise';
import ProfileService from 'services/Profile';
import ContactService from 'services/Contact';
import InvestmentAccountService from 'services/InvestmentAccount';
import { LoadingContext } from 'context/Loading';
import { eachSeries } from 'async';
import ChangePasswordModal from 'components/Modal/ChangePasswordModal';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import TabsHeader from 'components/Admin/TabsHeader';

const docs = [{ id: 1, name: 'Escritura de ampliación capital.pdf' },
  { id: 2, name: 'Escritura de retenciones 2022.pdf' },
  { id: 3, name: 'Escritura de retenciones 2023.pdf' }];

function Profile() {
  const location = useLocation();
  const hasAdmin = location?.pathname?.indexOf( 'admin' ) !== -1;
  const [accounts, setAccounts] = useState<any[]>( [] );
  const [profile, setProfile] = useState<any>();
  const [categories, setCategories] = useState<any>();
  const { toggleLoading } = useContext( LoadingContext );
  const navigate = useNavigate();

  const getAccount = useCallback( async () => {
    toggleLoading( true );
    const [, categoryResponse, categoryData] = await handlePromise( ContactService.getCategory() );
    const [, profileResponse, profileData] = await handlePromise( ProfileService.getProfile() );
    if ( profileData.isNew ) {
      navigate( '/force-change-password' );
      return;
    }
    if ( profileResponse.ok ) setProfile( profileData );
    if ( categoryResponse.ok ) setCategories( categoryData );

    if ( !hasAdmin ) {
      const [, accountsResponse, accountsData] = await handlePromise(
        InvestmentAccountService.getInvestmentAccounts(),
      );
      if ( accountsResponse.ok ) {
        const accountsDataOk: any[] = [];
        eachSeries(
          _.sortBy( _.filter( accountsData, { miInversion: true } ), 'nameCuentadeInversion' ),
          ( item: any, cb ) => {
            if ( item?.tipoCuenta === 'Empresa' ) {
              handlePromise(
                InvestmentAccountService.getInvestmentGetEmpresa( item.cuentaDeInversionId ),
              ).then( ( response ) => {
                const [accountError, accountResponse, accountData] = response;
                /* const [, documentProjectResponse, documentProjectData] = await handlePromise(
                  ProjectService.getDocumentacion( userAccountId, id ),
                ); */
                if ( accountResponse.ok ) {
                  accountsDataOk.push( {
                    ...item,
                    detail: accountData,
                    documents: docs,
                  } );
                  cb( );
                } else {
                  cb( accountError );
                }
              } ).catch( ( err ) => {
                cb( err );
              } );
            }
            if ( item?.tipoCuenta === 'Particular' ) {
              handlePromise(
                InvestmentAccountService.getInvestmentGetPersonal( item.cuentaDeInversionId ),
              ).then( ( response ) => {
                const [accountError, accountResponse, accountData] = response;
                if ( accountResponse.ok ) {
                  accountsDataOk.push( {
                    ...item,
                    detail: {
                      ...accountData,
                      fechaNacimiento: accountData.fechaNacimiento.slice( 0, 10 ),
                    },
                    documents: docs,
                  } );
                  cb( );
                } else {
                  cb( accountError );
                }
              } ).catch( ( err ) => {
                cb( err );
              } );
            }
          },
          ( err ) => {
            if ( err ) {
              // console.log( err );
            } else {
              setAccounts( accountsDataOk );
            }
            setTimeout( () => {
              toggleLoading( false );
            }, 800 );
          },
        );
      } else {
        setTimeout( () => {
          toggleLoading( false );
        }, 800 );
      }
    } else {
      setTimeout( () => {
        toggleLoading( false );
      }, 800 );
    }
  }, [] );

  useEffect( () => {
    getAccount();
  }, [] );
  const [showContactModal, setContactModal] = useState( false );
  const [showChangePasswordModal, setChangePasswordModal] = useState( false );

  const toggleContactModal = useCallback( () => {
    setContactModal( ( state ) => !state );
  }, [] );

  const toggleChangePasswordModal = useCallback( () => {
    setChangePasswordModal( ( state ) => !state );
  }, [] );

  if ( hasAdmin ) {
    return (
      <>
        <TabsHeader />
        <Container fluid="lg" className="body-container">
          <ProfileView
            onOpenContactModal={toggleContactModal}
            onOpenChangePasswordModal={toggleChangePasswordModal}
            profile={profile}
            accounts={accounts}
          />
          <ContactModal
            show={showContactModal}
            onClose={toggleContactModal}
            data={categories}
          />
          <ChangePasswordModal
            show={showChangePasswordModal}
            onClose={toggleChangePasswordModal}
          />
        </Container>
      </>
    );
  }
  return (
    <>
      <ProfileView
        onOpenContactModal={toggleContactModal}
        onOpenChangePasswordModal={toggleChangePasswordModal}
        profile={profile}
        accounts={accounts}
      />
      <ContactModal
        show={showContactModal}
        onClose={toggleContactModal}
        data={categories}
      />
      <ChangePasswordModal
        show={showChangePasswordModal}
        onClose={toggleChangePasswordModal}
      />
    </>
  );
}

export default Profile;
