import React, { useCallback, useContext, useState } from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import validateForm from 'utils/Validator';
import useSubmit from 'hooks/useSubmit';
import ProfileService from 'services/Profile';
import { yupChangePassword } from 'utils/yupFields';
import handlePromise from 'utils/Promise';
import InvestmentAccountService from 'services/InvestmentAccount';
import { updateUserAccount, updateUserRole } from 'store/User';
import { ADMIN_ACCOUNTS } from 'constants/routes';
import { useDispatch } from 'react-redux';
import { User } from 'types/User';
import { Account } from 'types/Account';
import { LoadingContext } from 'context/Loading';
import RecoverPassword from 'views/RecoverPassword';
import SelectAccount from '../SelectAccount';

function ForceChangePassword() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const validationSchema = yup.object( yupChangePassword( t ) );
  const validate = validateForm( validationSchema );
  const { toggleLoading } = useContext( LoadingContext );
  const [showAccountsModal, setAccountsModal] = useState( false );
  const [profile, setProfile] = useState<User>();
  const [accounts, setAccounts] = useState<Account[]>( [] );

  const selectAccount = useCallback( ( accountId: string ) => {
    dispatch( updateUserAccount( accountId ) );
  }, [] );

  const getUser = useCallback( async () => {
    toggleLoading( true );
    const [, profileResponse, profileData] = await handlePromise( ProfileService.getProfile() );
    if ( profileResponse.ok ) setProfile( profileData );

    if ( profileData.isNew ) {
      return;
    }

    const [, accountsResponse, accountsData] = await handlePromise(
      InvestmentAccountService.getInvestmentAccounts(),
    );
    if ( accountsResponse.ok ) setAccounts( accountsData );

    dispatch( updateUserRole( profileData?.role ) );

    if ( profileData?.role === 'Admin' && !accountsData?.length ) {
      selectAccount( 'admin' );
      navigate( ADMIN_ACCOUNTS );
    } else if ( profileData?.role !== 'Admin' && accountsData?.length === 1 ) {
      selectAccount( accountsData[0].cuentaDeInversionId );
    } else if ( ( accountsData?.length > 1 ) || ( profileData?.role === 'Admin' && !!accountsData?.length ) ) {
      setAccountsModal( true );
    }
    toggleLoading( false );
  }, [] );

  const submitForm = useSubmit( {
    promise: ( formData ) => ProfileService.putChangePassword( formData ),
    callback: () => getUser(),
    showAlert: false,
  } );

  return (
    <>
      <RecoverPassword validate={validate} onSubmit={submitForm} force />
      <SelectAccount
        show={showAccountsModal}
        userRole={profile?.role}
        accounts={accounts}
        selectAccount={selectAccount}
        onClose={() => setAccountsModal( false )}
      />
    </>
  );
}

export default ForceChangePassword;
