import { useState, useCallback } from 'react';
import _ from 'lodash';

import handlePromise from 'utils/Promise';

type HookProps = {
  initialState?: {
    data: any[],
    totalItems: number
    pageCount: number
  };
  deps?: any[],
  promise: ( data: any ) => Promise<any>;
  conditional?: boolean,
};

export default ( {
  initialState = { data: [], totalItems: 0, pageCount: 0 }, promise, conditional, deps = [],
}:HookProps ) => {
  const [data, setData] = useState<{
    data: any[],
    totalItems: number,
    pageCount: number,
  }>( initialState );
  const [dataLoading, setDataLoading] = useState<boolean>( false );

  const fetchData = useCallback( ( apiParams: any, pageSize: number ) => {
    const getData = async ( params: any ) => {
      setDataLoading( true );
      const [, response, responseData] = await handlePromise( promise( params ) );
      setDataLoading( false );

      // eslint-disable-next-line no-empty
      if ( !response.ok ) {

      }
      setData( _.isEmpty( responseData ) ? initialState
        : { ...responseData, pageCount: Math.ceil( responseData.totalItems / pageSize ) } );
    };
    if ( conditional === undefined || conditional ) getData( apiParams );
  }, [...deps] ); //eslint-disable-line

  return { fetchData, data, dataLoading };
};
