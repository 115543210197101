import React from 'react';
import {
  Modal, Button, Row, Col,
} from 'react-bootstrap';
import { FormProps } from 'react-final-form';
import TextField from 'components/Form/FormTextField';
import Form from 'components/Form/Layout';
import { ValidationError } from 'yup';
import { useTranslation } from 'react-i18next';
import SuccessMessage from '../../components/Modal/SuccessMessage';

type ForgotPasswordProps = {
  show: boolean,
  formSubmitted: boolean,
  validate: ( values: any ) => Promise<ValidationError>,
  onClose: () => void,
  onSubmit: ( values: any ) => void,
};

function ForgotPassword( {
  validate, show, formSubmitted, onClose, onSubmit,
}: ForgotPasswordProps ) {
  const { t } = useTranslation();
  return (
    <Modal
      show={show}
      centered
      onHide={onClose}
    >
      <Modal.Header closeButton />
      <Modal.Body>
        <h3 className="text-center mb-md-4 mb-3">{t( 'forgotPassword.title' )}</h3>
        <p className="text-center">{t( 'forgotPassword.description' )}</p>

        {formSubmitted
          ? (
            <SuccessMessage description={t( 'forgotPassword.emailSent' )} onClose={onClose} />
          )
          : (
            <Form
              validate={validate}
              onSubmit={onSubmit}
              body={(
                <div className="pt-4">
                  <Row>
                    <Col md={9} className="m-auto">
                      <TextField
                        field="email"
                        label="email"
                      />
                    </Col>
                  </Row>
                </div>
              )}
              footer={( form: FormProps ) => (
                <Row>
                  <Col md={9} className="m-auto">
                    <Button variant="primary" type="submit" className="btn--full-width" disabled={form.getState().invalid}>
                      {t( 'accept' )}
                    </Button>
                  </Col>
                </Row>
              )}
            />
          )}
      </Modal.Body>
    </Modal>
  );
}

export default ForgotPassword;
