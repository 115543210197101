import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { State } from 'types/Store';
import { User } from 'types/User';

const initialState = {
  role: undefined,
};

export const user = createSlice( {
  name: 'user',
  initialState,
  reducers: {
    updateUserToken: ( state: User, action: PayloadAction<Partial<string>> ) => {
      state.accessToken = action.payload;
    },
    updateUserAccount: ( state: User, action: PayloadAction<Partial<string>> ) => {
      state.account = action.payload;
    },
    updateUserRole: ( state: User, action: PayloadAction<Partial<string>> ) => {
      state.role = action.payload;
    },
    logoutUser: () => ( initialState ),
  },
} );

export const {
  updateUserToken,
  updateUserAccount,
  updateUserRole,
  logoutUser,
} = user.actions;

export const selectUser = ( state: State ) => state.user;

export const selectUserAccount = ( state: State ) => state.user?.account;
export const selectUserRole = ( state: State ) => state.user?.role;

export default user.reducer;
