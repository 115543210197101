import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import validateForm from 'utils/Validator';
import ForgotPasswordView from 'views/ForgotPassword';
import useSubmit from 'hooks/useSubmit';
import AuthService from 'services/Auth';

type ForgotPasswordProps = {
  show: boolean,
  onClose: () => void
};

function ForgotPassword( { show, onClose }:ForgotPasswordProps ) {
  const [formSubmitted, setFormSubmitted] = useState( false );
  const validationSchema = yup.object( {
    email: yup.string().required().email(),
  } );
  const validate = validateForm( validationSchema );

  useEffect( () => {
    if ( !show ) setFormSubmitted( false );
  }, [show] );

  const submitForm = useSubmit( {
    promise: ( formData ) => AuthService.forgotPassword( {
      email: formData.email,
    } ),
    callback: () => setFormSubmitted( true ),
    showAlert: false,
  } );

  return (
    <ForgotPasswordView
      formSubmitted={formSubmitted}
      show={show}
      validate={validate}
      onClose={onClose}
      onSubmit={submitForm}
    />
  );
}

export default ForgotPassword;
