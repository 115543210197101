import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import HistoricView from 'views/User/Historic';
import handlePromise from 'utils/Promise';
import ProjectService from 'services/Projects';
import { KpiProject } from 'types/Project';
import { useSelector } from 'react-redux';
import useFetchTableData from 'hooks/useFetchTableData';
import { selectUserAccount } from 'store/User';
import { LoadingContext } from 'context/Loading';
import { getFile } from 'utils/downloadBase64';

function Historic() {
  const userAccountId = useSelector( selectUserAccount );
  const [kpiProject, setKpiProject] = useState<KpiProject | null>( null );
  const { toggleLoading } = useContext( LoadingContext );

  const { fetchData, data, dataLoading } = useFetchTableData( {
    promise: ( params ) => ProjectService.getHistorical( userAccountId, params ),
    deps: [userAccountId],
  } );

  const downloadFilePdf = useCallback( async ( ) => {
    toggleLoading( true );
    const [, , downloadDocumentData] = await handlePromise(
      ProjectService.getHistoricoPdfByCuentaInversion( userAccountId, { responseType: 'blob' } ),
    );

    setTimeout( () => {
      toggleLoading( false );
    }, 800 );

    if ( downloadDocumentData ) {
      getFile( downloadDocumentData, 'Movimientos', 'application/pdf', 'pdf' );
    }
  }, [userAccountId] );

  const getData = useCallback( async () => {
    const [, kpiProjectResponse, kpiProjectData] = await handlePromise(
      ProjectService.getKpiHistorical( userAccountId ),
    );
    if ( kpiProjectResponse.ok ) setKpiProject( kpiProjectData );
  }, [userAccountId] );

  useEffect( () => {
    getData();
  }, [userAccountId] );

  return (
    <HistoricView
      downloadFilePdf={downloadFilePdf}
      kpiProject={kpiProject}
      projects={data}
      fetchData={fetchData}
      isLoading={dataLoading}
    />
  );
}
export default Historic;
