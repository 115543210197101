import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import {
  DASHBOARD, FOUND_PROJECTS, FUNDS, HISTORIC, PROJECTS,
} from 'constants/routes';
import {
  Container, Dropdown, Nav, NavDropdown,
} from 'react-bootstrap';
import { convertToSlug } from 'utils/utils';
import { useTranslation } from 'react-i18next';

function TabsHeader( { projects }:{ projects:any[] } ) {
  const { t } = useTranslation();
  const location = useLocation();
  return (
    <div className="nav__subheader d-none d-md-block">
      <Container>
        <Nav variant="tabs">
          <Nav.Item>
            <Nav.Link as={NavLink} to={DASHBOARD} end>
              Posición global
            </Nav.Link>
          </Nav.Item>

          {( !!projects.length )
          && (
          <NavDropdown
            title={t( 'activeInvestments' )}
            className="nav__subheader-projects"
            id="projects-nav-dropdown"
            active={location.pathname.startsWith( PROJECTS )
                || location.pathname.startsWith( FUNDS )
                || location.pathname.startsWith( FOUND_PROJECTS )}
          >
            {projects.map( ( item ) => (
              !item.relatedProjects?.length
                ? (
                  <NavDropdown.Item
                    key={item.promocionId}
                    as={NavLink}
                    to={item.tipoDeProyecto === 'Fondo'
                      ? `${FUNDS}/${item.promocionId}/${convertToSlug( item.codigoPromocion )}`
                      : `${PROJECTS}/${item.promocionId}/${convertToSlug( item.codigoPromocion )}`}
                    // activeClassName=""
                    className="text-decoration-none"
                  >
                    {item.codigoPromocion}
                  </NavDropdown.Item>
                )
                : (
                  <Dropdown as={Nav.Item} drop="end" className="d-flex align-items-center">
                    <NavDropdown.Item
                      key={item.promocionId}
                      as={NavLink}
                      to={`${FUNDS}/${item.promocionId}/${convertToSlug( item.codigoPromocion )}`}
                      className="text-decoration-none"
                    >
                      {item.codigoPromocion}
                    </NavDropdown.Item>
                    <Dropdown.Toggle
                      as={Nav.Link}
                      split
                      className="py-0"
                    />
                    <Dropdown.Menu>
                      {item.relatedProjects.map( ( relatedProject: any ) => (
                        <NavDropdown.Item
                          key={relatedProject.promocionId}
                          as={NavLink}
                          to={`${FOUND_PROJECTS}/${relatedProject.promocionId}/${convertToSlug( relatedProject.codigoPromocion )}`}
                          // activeClassName=""
                          className="text-decoration-none"
                        >
                          {relatedProject.codigoPromocion}
                        </NavDropdown.Item>
                      ) )}
                    </Dropdown.Menu>
                  </Dropdown>
                )
            ) )}
          </NavDropdown>
          )}

          <Nav.Item>
            <Nav.Link as={NavLink} to={HISTORIC}>
              Histórico
            </Nav.Link>
          </Nav.Item>
          {/* <Nav.Item>
            <Nav.Link as={NavLink} to={NEWS}>Noticias</Nav.Link>
          </Nav.Item> */}
        </Nav>
      </Container>
    </div>
  );
}

export default TabsHeader;
