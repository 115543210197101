import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const settings = createSlice( {
  name: 'settings',
  initialState,
  reducers: {},
} );

export default settings.reducer;
