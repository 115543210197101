import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';
import Table from 'components/Table';
import BodyContent from 'components/BodyContent';
import TextField from 'components/Form/TextField';
import DarkTile from 'components/DarkTile';
import icon1 from 'assets/img/dashboard-icon1.svg';
import icon2 from 'assets/img/dashboard-icon2.svg';
import icon3 from 'assets/img/dashboard-icon3.svg';
import icon4 from 'assets/img/dashboard-icon4.svg';
import { KpiProject } from 'types/Project';
import { DTable } from 'types/table';
import { formatCurrencyNoDecimal, formatPercentOneDecimal } from 'utils/formats';
import numeral from 'numeral';
import MillionNumeral from 'components/MillionNumeral';
import RMobileTable from 'components/MobileTable';
import { useNavigate } from 'react-router-dom';
import { convertToSlug } from 'utils/utils';
import { HiOutlineDownload } from 'react-icons/hi';

function Historic( {
  kpiProject, projects, fetchData, isLoading, downloadFilePdf,
}:{
  isLoading?:boolean;
  projects:DTable;
  kpiProject:KpiProject | null ;
  downloadFilePdf:() =>void ;
  fetchData: ( params:any, pageSize:number )=>void ; } ) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <BodyContent
      title={(
        <div className="px-3 pt-3 pb-2">
          <h1>{t( 'historic' )}</h1>
        </div>
      )}
    >
      <div className="row align-items-stretch g-3 g-md-4 mb-4">
        <DarkTile
          icon={icon3}
          title={( <MillionNumeral number={kpiProject?.capitalInvertido} /> )}
          subtitle="Capital Invertido"
        />
        <DarkTile
          icon={icon2}
          title={( <MillionNumeral number={kpiProject?.beneficios} /> )}
          subtitle="Beneficios"
          subtitle2={t( 'beforeFee' )}
        />
        <DarkTile
          icon={icon1}
          title={numeral( kpiProject?.roi ? Number( kpiProject?.roi ) / 100 : 0 )
            .format( formatPercentOneDecimal )}
          subtitle="ROE medio"
          subtitle2={t( 'beforeFee' )}
        />
        <DarkTile
          icon={icon4}
          title={numeral( kpiProject?.tir ? kpiProject.tir / 100 : 0 )
            .format( formatPercentOneDecimal )}
          subtitle="TIR media"
          subtitle2={t( 'beforeFee' )}
        />
      </div>

      <div className="d-md-none">
        <RMobileTable
          data={projects.data}
          pageCount={projects.pageCount}
          fetchData={fetchData}
          isLoading={isLoading}
          specialText={t( 'beforeFee' )}
          header={( filters, setFilters ) => (
            <Row className="align-items-center">
              <Col lg={9} sm={8} xs={6}>
                <h3 className="text--lg">{t( 'projects.totalProjects', { total: projects?.totalItems } )}</h3>
              </Col>
              <Col lg={3} sm={4} xs={6}>
                <TextField
                  value={filters.codigoPromocion}
                  classNames={{ formGroup: 'input-filters' }}
                  showErrors={false}
                  placeholder="search"
                  onChange={( value ) => setFilters( ['codigoPromocion'], value )}
                  appendIcon="icon-search text-primary"
                />
              </Col>
            </Row>
          )}
        />
      </div>
      <div className="d-none d-md-block">
        <Table
          columns={[
            {
              Header: t( 'project' ),
              accessor: 'codigoPromocion',
              Cell: ( { cell }:CellProps<any> ) => (
                <Button
                  className="p-0"
                  variant="link"
                  onClick={() => {
                    navigate( `/projects/${cell.row.original.promocionId}/${convertToSlug( cell.value )}` );
                  }}
                >
                  <span className="text--bold text--normal text--md">{cell.value}</span>
                </Button>
              ),
            },
            {
              className: 'text--lato',
              Header: t( 'start' ),
              accessor: 'inicio',
            },
            {
              className: 'text--lato',
              Header: t( 'end' ),
              accessor: 'fin',
            },
            {
              Header: t( 'investedCapital' ),
              accessor: 'capitalInvertido',
              className: 'text--right text--lato',
              headerClassName: 'text--right',
              Cell: ( { cell }: CellProps<any> ) => (
                numeral( cell.value ).format( formatCurrencyNoDecimal ) ),
            },
            {
              Header: `${t( 'profit' )}*`,
              headerClassName: 'text--right',
              accessor: 'beneficio',
              className: 'text--right text--lato',
              Cell: ( { cell }: CellProps<any> ) => (
                <div>
                  <span>{numeral( cell.value ).format( formatCurrencyNoDecimal ) }</span>
                  <span>{cell.row.original.pagosCompletados ? '' : '**'}</span>
                  <span className="text-white">{cell.row.original.pagosCompletados ? '**' : ''}</span>
                </div>
              ),
            },
            {
              headerClassName: 'text--right',
              Header: `${t( 'roi' )}*`,
              accessor: 'roi',
              className: 'text--right text--lato',
              Cell: ( { cell }: CellProps<any> ) => (
                numeral( cell.value / 100 ).format( formatPercentOneDecimal ) ),
            },
            {
              headerClassName: 'text--right',
              accessor: 'tir',
              Header: `${t( 'tir' )}*`,
              className: 'text--right text--lato',
              Cell: ( { cell }: CellProps<any> ) => (
                <span>
                  {numeral( cell.value / 100 ).format( formatPercentOneDecimal ) }
                </span>
              ),
            },
          ]}
          isLoading={isLoading}
          total={projects.totalItems}
          fetchData={fetchData}
          pageCount={projects.pageCount}
          data={projects.data}
          specialText={(
            <>
              <span>
                {t( 'beforeFee' )}
              </span>
              <br />
              <span>
                {t( 'needDistribution' )}
              </span>
            </>
          )}
          cardHeader={(
            <Row>
              <Col className="text--right">
                <Button
                  variant="link"
                  size="sm"
                  className="text--medium p-0 mb-2"
                  onClick={downloadFilePdf}
                >
                  <HiOutlineDownload color="#166B8E" size={22} />
                </Button>
              </Col>
            </Row>
          )}
          header={( filters, setFilters ) => (
            <Row className="align-items-center">
              <Col lg={9} xs={6} sm={8}>
                <h3 className="text--lg">
                  {t( 'projects.totalProjects', { total: projects?.totalItems } )}
                </h3>
              </Col>
              <Col lg={3} xs={6} sm={4}>
                <TextField
                  classNames={{ formGroup: 'input-filters' }}
                  value={filters.codigoPromocion}
                  showErrors={false}
                  placeholder="search"
                  appendIcon="icon-search text-primary"
                  onChange={( value ) => setFilters( ['codigoPromocion'], value )}
                />
              </Col>
            </Row>
          )}
        />
      </div>
    </BodyContent>
  );
}

export default Historic;
