import React, {
  createContext, ReactNode, useCallback, useMemo, useState,
} from 'react';

export const LoadingContext = createContext( {
  loading: false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  toggleLoading: ( loading: boolean ) => {},
} );

function LoadingProvider( { children }: { children: ReactNode } ) {
  const [loading, setLoading] = useState( false );

  const toggleLoading = useCallback( ( isLoading: boolean ) => {
    setLoading( isLoading );
  }, [] );

  const value = useMemo( () => ( {
    loading,
    toggleLoading,
  } ), [loading] );

  return (
    <LoadingContext.Provider value={value}>
      {children}
    </LoadingContext.Provider>
  );
}

export default LoadingProvider;
