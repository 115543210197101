import React, { ReactNode } from 'react';
import { Row, Col, Card } from 'react-bootstrap';

type DarkTitleProps = {
  icon?: any;
  iconSize?: number;
  title: string | ReactNode;
  subtitle: string;
  subtitle2?: string;
};
function DarkTile( {
  icon, title, subtitle, subtitle2, iconSize,
}: DarkTitleProps ) {
  return (
    <Col className="p-1">
      <Card className="card--light card--sm h-100" body>
        <Row className="align-items-center h-md-100">
          <Col xs="auto" className="pe-0">
            <div className="icon--rounded bg-dark">
              <img
                src={icon}
                alt=""
                style={{
                  width: iconSize,
                }}
              />
            </div>
          </Col>
          <Col className="pe-1">
            <h3 className="d-none d-md-block text--xmmd">{title}</h3>
            <p className="text--sm text--medium text--gray8 m-0">{subtitle}</p>
          </Col>
          <Col xs={12}>
            <h3 className="d-md-none">{title}</h3>
            {!!subtitle2 && <p className="text--xxs text--gray8 m-0">{subtitle2}</p>}
          </Col>
        </Row>
      </Card>
    </Col>
  );
}

export default DarkTile;
