import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { setLocale } from 'yup';
import moment from 'moment';
import 'moment/locale/es';

import es from './es.json';

moment.locale( 'es' );

const resources = { es: { translation: es } };

i18n
  .use( initReactI18next )
  .init( {
    resources,
    lng: 'es',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  } );

setLocale( {
  mixed: {
    required: 'form.requiredField',
    oneOf: 'form.equalFields',
  },
  string: {
    email: 'form.email',
  },
} );

export default i18n;
