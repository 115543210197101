import React, { useEffect, useState } from 'react';
import {
  Carousel, OverlayTrigger,
  Table, Tooltip,
} from 'react-bootstrap';
import { charColors, charProjects } from 'utils/chars';
import numeral from 'numeral';
import _ from 'lodash';
import {
  formatCurrencyNoDecimal, formatPercent, formatPercentOneDecimal,
} from 'utils/formats';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import CardLight from '../../CardLight';

type CharDoughnutProps = {
  dataObjs: any[] | undefined ;
  title: string;
  itemKey: string;
  label: string;
  val: string;
  value?: string;
  widthValue?: string;
  formatVal: any;
  showHeader?: boolean;
  indicators?: boolean;
  id?: any;
  headers: any[];
  colorList?: any[];
  format?: string;
  unit?: string;
  minHeight?: string;
  fullHeight?: boolean;
};
const CharDoughnut = React.memo( ( {
  dataObjs, title, label, val, value, formatVal, id, headers, showHeader = true, itemKey,
  colorList = charColors, indicators = true, widthValue = '110px',
  format, unit, minHeight = '235px',
  fullHeight = true,
}: CharDoughnutProps ) => {
  const { t } = useTranslation();
  const [indexSlide, setIndexSlide] = useState( 0 );
  const [pages, setPages] = useState( 0 );
  const handleSelect = ( selectedIndex: React.SetStateAction<number> ) => {
    setIndexSlide( selectedIndex );
  };

  useEffect( () => {
    if ( !!dataObjs && dataObjs.length ) {
      setPages( Math.ceil( dataObjs.length / 4 ) );
      setIndexSlide( 0 );
      const labels1 = dataObjs.map( ( item ) => t( item[label] ) );
      const data1 = dataObjs.map( ( item ) => item[val] );
      let total = 0;
      data1.forEach( ( item ) => { total += item; } );
      const fullData = dataObjs.map( ( item ) => ( {
        label: t( item[label] ),
        val: value ? item[value] : item[val],
      } ) );
      if ( process.env.NODE_ENV !== 'test' ) {
        const charProject = charProjects( {
          itemKey,
          labels: labels1,
          data: data1,
          fullData,
          colors: colorList,
          total,
          format,
          unit,
        } );
        return () => {
          charProject.destroy();
        };
      }
    }
  }, [dataObjs] );

  return (
    <CardLight
      title={title}
      isLoading={!dataObjs}
      fullHeight={fullHeight}
    >
      <div style={{ width: 195, height: 195 }} className="m-auto">
        <canvas id={itemKey} />
      </div>
      <Carousel
        activeIndex={indexSlide}
        onSelect={handleSelect}
        controls={false}
        touch
        variant="dark"
        interval={20000}
        className="carousel-dash"
        indicators={indicators}
      >
        {!!pages && _.map( [...Array( pages )], ( pageContent, pageIndex ) => (
          <Carousel.Item
            style={{ minHeight }}
            key={pageIndex}
          >
            <Table
              borderless
              className="table--info mt-2 mb-4"
            >
              {showHeader ? (
                <thead>
                  <tr>
                    {headers[0] && ( <th style={{ width: '25px' }}>{headers[0]}</th> )}
                    {headers[1] && ( <th>{headers[1]}</th> )}
                    {headers[2] && ( <th className="text--right" style={{ maxWidth: '50px' }}>{headers[2]}</th> )}
                    {headers[3] && ( <th className="text--right" style={{ width: widthValue }}>{headers[3]}</th> )}
                  </tr>
                </thead>
              ) : null}
              <tbody className={classnames( {
                'mt-5': !showHeader,
              } )}
              >
                {!!dataObjs && !!dataObjs.length && dataObjs.map( ( item, index ) => {
                  if ( index >= pageIndex * 4 && index <= ( pageIndex * 4 ) + 3 ) {
                    return (
                      <tr
                        key={`${item[id]}-${item[label]}`}
                      >
                        {headers[0] && (
                        <td style={{ width: '25px' }}>
                          <div className="chart-legend" style={{ backgroundColor: colorList[index] }} />
                        </td>
                        )}
                        {headers[1] && (
                        <td className="text-ellipsis w-auto" style={{ maxWidth: 0 }}>
                          <OverlayTrigger
                            placement="top"
                            overlay={(
                              <Tooltip id={`${item[id]}-${item[label]}`}>
                                {t( item[label] )}
                              </Tooltip>
                            )}
                          >
                            <span className="text--medium">
                              {t( item[label] )}
                            </span>
                          </OverlayTrigger>
                        </td>
                        )}
                        {headers[2] && (
                        <td className="text--right">
                          <div className="text--medium">
                            {item.unidades}
                          </div>
                        </td>
                        )}
                        {headers[3] && (
                        <td className="text--right" style={{ width: widthValue }}>
                          {formatVal === formatCurrencyNoDecimal && (
                          <div className="text--lato-bold">{numeral( item[val] ).format( formatVal )}</div>
                          )}
                          {( formatVal === formatPercent
                              || formatVal === formatPercentOneDecimal ) && (
                              <div className="text--lato-bold">{numeral( item[val] > 0 ? item[val] / 100 : 0 ).format( formatVal )}</div>
                          )}
                        </td>
                        )}
                      </tr>
                    );
                  }
                  return null;
                } )}
              </tbody>
            </Table>
          </Carousel.Item>
        ) )}
      </Carousel>
    </CardLight>
  );
} );

export default CharDoughnut;
