import React from 'react';
import * as yup from 'yup';
import qs from 'qs';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import validateForm from 'utils/Validator';
import RecoverPasswordView from 'views/RecoverPassword';
import useSubmit from 'hooks/useSubmit';
import AuthService from 'services/Auth';
import { yupForcePassword } from 'utils/yupFields';

function RecoverPassword() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const validationSchema = yup.object( yupForcePassword( t ) );
  const validate = validateForm( validationSchema );

  const submitForm = useSubmit( {
    promise: ( formData ) => AuthService.recoverPassword( {
      token: qs.parse( location.search, { ignoreQueryPrefix: true } )?.token as string,
      password: formData.password,
      confirmPassword: formData.confirmPassword,
    } ),
    callback: () => navigate( '/login' ),
    showAlert: false,
  } );

  return (
    <RecoverPasswordView
      onSubmit={submitForm}
      validate={validate}
    />
  );
}

export default RecoverPassword;
