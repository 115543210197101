import React from 'react';

type NoDataTextProps = {
  text: string;
};
function NoDataText( { text }: NoDataTextProps ) {
  return <p className="text--gray8 text-center text--sm p-3 pt-5">{text}</p>;
}

export default NoDataText;
