import _ from 'lodash';
import moment from 'moment';
import * as am4charts from '@amcharts/amcharts4/charts';
import numeral from 'numeral';
import * as am4maps from '@amcharts/amcharts4/maps';
import am4geodataSpain from '@amcharts/amcharts4-geodata/spainProvincesLow';
import * as am4core from '@amcharts/amcharts4/core';
// @ts-ignore
import { geoConicConformalSpain } from 'd3-composite-projections';
import markerOrangeImg from 'assets/img/marker-orange.svg';
import markerBlackImg from '../assets/img/marker-black.svg';
import { coordsProvince } from './chars';
import { formatCurrencyNoDecimal } from './formats';

export const CashFlowChart = ( dataObjs: any[] ) => {
  const formatedData = {};
  _.forEach( dataObjs, ( item ) => {
    const key = `${item.annio}-${item.trimestre}`;
    // @ts-ignore
    if ( formatedData[key] ) {
      // @ts-ignore
      formatedData[key].push( item );
    } else {
      // @ts-ignore
      formatedData[key] = [item];
    }
  } );

  const chartData: any = [];
  const years: any = [];
  const quarter = moment().quarter();
  const year = moment().format( 'YYYY' );
  const currentQ = `${moment().year()}-Q${moment().quarter()}`;

  _.forEach( formatedData, ( item: any ) => {
    const firstProject = _.get( item, '[0]', null );
    if ( firstProject ) {
      const currentYear = `${item[0].annio}-${item[0].trimestre}`;
      const label = years.indexOf( item[0].annio ) === -1 ? `${item[0].annio} -` : ' -';
      years.push( item[0].annio );
      const currentData: any = {
        year: currentYear,
        totalBalance: 0,
        label,
      };
      currentData.totalBalance = ( !!item && !!item.length ) ? _.get( item, `[${item.length - 1}].saldoAcumulado`, 0 ) : 0;
      currentData.totalBalance = Math.round( currentData.totalBalance ) * -1;

      // capitalContribution
      let subAporteCapital = 0;
      _.forEach( item, ( subItem: any ) => {
        subAporteCapital += subItem.aporteCapital;
      } );
      if ( subAporteCapital > 0 ) {
        currentData.capitalContribution = Math.round( subAporteCapital ) * -1;
      }

      // distributionLlamadaCapital
      let distributionLlamadaCapital = 0;
      _.forEach( item, ( subItem: any ) => {
        distributionLlamadaCapital += subItem.llamadaCapital;
      } );
      if ( distributionLlamadaCapital > 0 ) {
        currentData.distributionLlamadaCapital = Math.round( distributionLlamadaCapital ) * -1;
      }

      let subRepartoCapital = 0;
      _.forEach( item, ( subItem: any ) => {
        subRepartoCapital += subItem.repartoCapital;
      } );
      let subRepartoCapitalEstimado = 0;
      _.forEach( item, ( subItem: any ) => {
        subRepartoCapitalEstimado += subItem.repartoCapitalEstimado;
      } );

      let subRepartoBeneficios = 0;
      let subRepartoBeneficiosEstimados = 0;
      const trimestreNumber = firstProject.trimestre?.toString().replace( 'Q', '' );
      if ( ( firstProject.annio.toString() === year.toString()
              && trimestreNumber * 1 === quarter ) ) {
        currentData.repartoCapitalEstimado = Math.round( subRepartoCapital );
      }

      if ( ( firstProject.annio < year
          || ( firstProject.annio.toString() === year.toString()
              && trimestreNumber * 1 < quarter ) ) ) {
        _.forEach( item, ( subItem: any ) => {
          subRepartoBeneficios += subItem.repartoBeneficios;
        } );
        subRepartoCapital += subRepartoBeneficios + subRepartoCapitalEstimado;
        currentData.capitalDistribution = Math.round( subRepartoCapital );
      } else {
        _.forEach( item, ( subItem: any ) => {
          subRepartoBeneficiosEstimados += subItem.repartoBeneficiosEstimados;
        } );
        const b1 = subRepartoBeneficiosEstimados + subRepartoCapital
            + Math.round( subRepartoCapitalEstimado );
        if ( b1 ) currentData.distributionEstimatedBenefits = Math.round( b1 );
      }

      currentData.extraData = item;
      if ( currentData.year === currentQ ) {
        currentData.color = am4core.color( '#555' );
        currentData.opacity = 1;
      }

      chartData.push( currentData );
    }
  } );

  const chart = am4core.create( 'chartDiv3', am4charts.XYChart );
  chart.data = chartData;
  chart.paddingLeft = 0;
  chart.paddingRight = 0;

  const categoryAxis = chart.xAxes.push( new am4charts.CategoryAxis() );
  categoryAxis.renderer.grid.template.disabled = true;
  categoryAxis.dataFields.category = 'year';
  categoryAxis.renderer.minGridDistance = 20;
  categoryAxis.renderer.grid.template.stroke = am4core.color( '#A0CA92' );
  categoryAxis.renderer.grid.template.strokeWidth = 20;
  const catLabel = categoryAxis.renderer.labels.template;
  catLabel.maxWidth = 120;
  catLabel.fontSize = 12;

  categoryAxis.renderer.labels.template.adapter.add( 'text', ( text, target ) => ( target.dataItem.dataContext
  // @ts-ignore
    ? `${target.dataItem.dataContext.label}` : '' ) );

  categoryAxis.events.on( 'sizechanged', ( ev ) => {
    const axis = ev.target;
    const cellWidth = axis.pixelWidth / ( axis.endIndex - axis.startIndex );
    if ( cellWidth < axis.renderer.labels.template.maxWidth ) {
      axis.renderer.labels.template.rotation = 270;
      axis.renderer.labels.template.horizontalCenter = 'right';
      axis.renderer.labels.template.verticalCenter = 'middle';
    } else {
      axis.renderer.labels.template.rotation = 0;
      axis.renderer.labels.template.horizontalCenter = 'middle';
      axis.renderer.labels.template.verticalCenter = 'top';
    }
  } );

  /* const valueAxis = chart.yAxes.push( new am4charts.ValueAxis() );
  // @ts-ignore
  valueAxis.tooltip.disabled = true;
  valueAxis.renderer.line.opacity = 0;
  valueAxis.renderer.ticks.template.disabled = true;
  valueAxis.renderer.grid.template.disabled = true;
  valueAxis.numberFormatter = new am4core.NumberFormatter();
  valueAxis.numberFormatter.numberFormat = '#,##a'; */

  const valueAxis2 = chart.yAxes.push( new am4charts.ValueAxis() );
  // @ts-ignore
  valueAxis2.tooltip.disabled = true;
  valueAxis2.renderer.ticks.template.disabled = true;
  valueAxis2.renderer.opposite = true;
  valueAxis2.numberFormatter = new am4core.NumberFormatter();
  valueAxis2.numberFormatter.numberFormat = '#,##a';

  const lineSeries = chart.series.push( new am4charts.LineSeries() );
  lineSeries.dataFields.categoryX = 'year';
  lineSeries.dataFields.valueY = 'totalBalance';
  lineSeries.fillOpacity = 1;
  lineSeries.strokeWidth = 0;
  lineSeries.fill = am4core.color( '#F0F0F0' );
  // @ts-ignore
  lineSeries.tooltip.getFillFromObject = false;
  // @ts-ignore
  lineSeries.tooltip.background.fill = am4core.color( '#FFFFFF' );
  // @ts-ignore
  lineSeries.tooltip.background.fillOpacity = 1;
  // @ts-ignore
  lineSeries.tooltip.label.fill = am4core.color( '#3C3C3B' );
  // @ts-ignore
  lineSeries.tooltip.label.fontSize = 14;
  // @ts-ignore
  lineSeries.tooltip.label.interactionsEnabled = true;
  // @ts-ignore
  lineSeries.tooltip.label.textAlign = 'middle';
  lineSeries.yAxis = valueAxis2;

  // --------
  const bullet = lineSeries.bullets.push( new am4charts.Bullet() );
  const line = bullet.createChild( am4core.Line );
  line.x1 = 0;
  line.y1 = -1000;
  line.x2 = 0;
  line.y2 = 1000;
  line.strokeOpacity = 0;
  line.strokeDasharray = '3,3';
  line.propertyFields.stroke = 'color';
  line.propertyFields.strokeOpacity = 'opacity';

  chart.cursor = new am4charts.XYCursor();
  chart.cursor.behavior = 'panX';
  chart.cursor.lineX.opacity = 0;
  chart.cursor.lineY.opacity = 0;
  chart.cursor.maxTooltipDistance = -1;

  chart.scrollbarX = new am4core.Scrollbar();
  chart.scrollbarX.parent = chart.bottomAxesContainer;
  chart.scrollbarX.height = 25;
  chart.scrollbarX.background.fill = am4core.color( '#3C3C3B' );
  chart.scrollbarX.thumb.background.fill = am4core.color( '#3C3C3B' );
  chart.scrollbarX.startGrip.background.fill = am4core.color( '#3C3C3B' );
  chart.scrollbarX.endGrip.background.fill = am4core.color( '#3C3C3B' );
  chart.scrollbarX.background.fillOpacity = 0.2;

  const series = [
    chart.series.push( new am4charts.ColumnSeries() ),
    chart.series.push( new am4charts.ColumnSeries() ),
    chart.series.push( new am4charts.ColumnSeries() ),
    chart.series.push( new am4charts.ColumnSeries() ),
    chart.series.push( new am4charts.ColumnSeries() ),
  ];

  function cornerRadius( radius: any, item: any ) {
    const { dataItem } = item;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let hasSeries = 0;
    series.forEach( ( serie ) => {
      // @ts-ignore
      if ( serie.dataFields.valueY === 'capitalContribution' || serie.dataFields.valueY === 'distributionLlamadaCapital' ) {
        if ( dataItem.dataContext[serie.dataFields.valueY] && !serie.isHidden && !serie.isHiding ) {
          hasSeries += 1;
        }
      }
    } );
    return hasSeries === 1 ? 24 : 0;
  }

  series.forEach( ( item1, index ) => {
    series[index].dataFields.categoryX = 'year';
    series[index].yAxis = valueAxis2;
    series[index].stacked = true;
    series[index].strokeWidth = 0;
    series[index].width = 20;
    // @ts-ignore
    series[index].tooltip.getFillFromObject = false;
    // @ts-ignore
    series[index].tooltip.background.fill = am4core.color( '#FFFFFF' );
    // @ts-ignore
    series[index].tooltip.background.fillOpacity = 1;
    // @ts-ignore
    series[index].tooltip.autoTextColor = false;
    // @ts-ignore
    series[index].tooltip.label.fill = am4core.color( '#3C3C3B' );
    // @ts-ignore
    series[index].tooltip.label.fontSize = 14;
    // @ts-ignore
    series[index].tooltip.pointerOrientation = 'horizontal';

    switch ( index ) {
      case 1:
        series[index].dataFields.valueY = 'capitalDistribution';
        series[index].fill = am4core.color( '#166B8E' );
        break;
      case 2:
        series[index].dataFields.valueY = 'repartoCapitalEstimado';
        series[index].fill = am4core.color( '#166B8E' );
        break;
      case 3:
        series[index].dataFields.valueY = 'distributionEstimatedBenefits';
        series[index].fill = am4core.color( '#EA6D1F' );
        break;
      case 4:
        series[index].dataFields.valueY = 'distributionLlamadaCapital';
        series[index].fill = am4core.color( '#FCD5B0' );
        break;
      default:
        series[index].dataFields.valueY = 'capitalContribution';
        series[index].fill = am4core.color( '#3C3C3B' );
    }

    series[index].adapter.add( 'tooltipHTML', ( text: any, target: any ) => {
      const data = target.tooltipDataItem.dataContext;
      const { extraData } = data;
      const tooltipText: any[] = [];

      extraData.forEach( ( item: any ) => {
        const trimestreNumber = item.trimestre?.toString().replace( 'Q', '' );
        let repartoText = 'Reparto';
        if ( ( item.annio > year
            || ( item.annio.toString() === year.toString()
                && trimestreNumber * 1 > quarter ) ) ) {
          repartoText = 'Reparto estimado';
        }

        if ( item.namePromocion ) {
          const contribution = item.aporteCapital > 0 ? `<div class="text-sm-center text--sm text--black1 mb-0 pb-0 text--lato-bold">Aporte: ${numeral( item.aporteCapital ).format( formatCurrencyNoDecimal )}${item.antesFeeExito ? '*' : '**'}</div>` : '';
          const distributionLlamadaCapital = item.llamadaCapital > 0 ? `<div class="text-sm-center text--sm text--black1 mb-0 pb-0 text--lato-bold">Capital call: ${numeral( item.llamadaCapital ).format( formatCurrencyNoDecimal )}</div>` : '';
          const distributionsVal = item.repartoBeneficios
              + item.repartoBeneficiosEstimados
              + item.repartoCapitalEstimado
              + item.repartoCapital;
          const distributions = distributionsVal > 0 ? `<div class="text-sm-center text--sm text--black1 mb-0 pb-0 text--lato-bold">${repartoText}: ${numeral( distributionsVal ).format( formatCurrencyNoDecimal )}${item.antesFeeExito ? '*' : '**'}</div>` : '';
          tooltipText.push( `<div class="text-sm-center text--gray7 text--sm text--xs mb-0 pb-0"><h6>${item.codigoPromocion}</h6></div>${contribution}${distributions}${distributionLlamadaCapital}` );
        }
      } );

      return tooltipText.join( '<hr class="mb-2 mt-2 pb-0 pt-0" />' );
    } );

    const columnTemplate = series[index].columns.template;
    columnTemplate.width = 12;
    columnTemplate.strokeOpacity = 0;

    if ( index === 0 ) {
      columnTemplate.column.adapter.add( 'cornerRadiusBottomLeft', cornerRadius );
      columnTemplate.column.adapter.add( 'cornerRadiusBottomRight', cornerRadius );
    }
    if ( index === 4 ) {
      columnTemplate.column.cornerRadiusBottomLeft = 24;
      columnTemplate.column.cornerRadiusBottomRight = 24;
    }
    if ( index === 3 || index === 1 ) {
      columnTemplate.column.cornerRadiusTopLeft = 24;
      columnTemplate.column.cornerRadiusTopRight = 24;
    }
  } );
  const match = window.matchMedia( '(max-width: 480px)' ).matches;

  if ( match ) {
    if ( chartData.length > 12 ) {
      chart.events.on( 'ready', () => {
        categoryAxis.zoomToIndexes( chartData.length - 12, chartData.length );
      } );
    }
  } else if ( chartData.length > 24 ) {
    const currentIndex = _.findIndex( chartData, { year: currentQ } );
    const endIndex = ( chartData.length - currentIndex ) >= 16
      ? currentIndex + 17 : chartData.length;
    chart.events.on( 'ready', () => {
      categoryAxis.zoomToIndexes( endIndex - 24, endIndex );
    } );
  }
};

export const MapCharAmchar = (
  dataObjs: any[],
  onOpenMapModal: ( { params }:{ params:any } ) => void,
) => {
  const chart = am4core.create( 'map', am4maps.MapChart );
  chart.geodata = am4geodataSpain;
  chart.projection.d3Projection = geoConicConformalSpain();

  const polygonSeries = chart.series.push( new am4maps.MapPolygonSeries() );
  polygonSeries.useGeodata = true;

  const polygonTemplate = polygonSeries.mapPolygons.template;
  polygonTemplate.strokeWidth = 0.75;
  polygonTemplate.fill = am4core.color( '#F0F0F0' );
  polygonTemplate.stroke = am4core.color( '#D9CBCB' );

  const imageSeries = chart.series.push( new am4maps.MapImageSeries() );
  const imageSeriesTemplate = imageSeries.mapImages.template;
  const marker = imageSeriesTemplate.createChild( am4core.Image );
  marker.propertyFields.href = 'flag';
  marker.width = 26;
  marker.height = 26;
  marker.nonScaling = true;
  marker.horizontalCenter = 'middle';
  marker.verticalCenter = 'middle';
  marker.tooltipPosition = 'pointer';
  marker.tooltipHTML = '<div style="font-size: 14px;text-align: center;background-color: #F0F0F0;padding: 8px 10px;border-top-left-radius: 8px;border-top-right-radius: 8px;min-width: 165px">{title}</div><div style="color: #166B8E;font-size: 12px;text-align: center;padding: 8px 10px;border-bottom-left-radius: 8px">Abrir mapa</div>';

  // @ts-ignore
  imageSeries.tooltip.label.padding( 0, 0, 0, 0 );
  // @ts-ignore
  imageSeries.tooltip.background.cornerRadius = 8;
  imageSeriesTemplate.propertyFields.latitude = 'latitude';
  imageSeriesTemplate.propertyFields.longitude = 'longitude';

  imageSeries.data = dataObjs.map( ( item ) => {
    const coors = coordsProvince( item.provincia );
    return {
      latitude: coors ? coors.latitude : 0,
      longitude: coors ? coors.longitude : 0,
      title: item.provincia,
      province: item.provincia,
      flag: item.inversion ? markerOrangeImg : markerBlackImg,
    };
  } );
  imageSeries.fill = am4core.color( '#FFFFFF' );
  imageSeries.fontSize = '10px';

  imageSeriesTemplate.events.on( 'hit', ( ev ) => {
    onOpenMapModal( { params: ev.target.dataItem.dataContext } );
  } );
};
