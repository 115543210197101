import api from './index';

export default {

  getCategory() {
    return api.get( 'api/categoria' );
  },
  postContact( data:any ) {
    return api.post( 'api/contactar', data );
  },
};
