import React, { useEffect, useState } from 'react';
import {
  Button, Col, Modal, Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ProfileService from 'services/Profile';
import useSubmit from 'hooks/useSubmit';
import SuccessMessage from '../SuccessMessage';
import { MODAL_ACTIVE_ADMIN, MODAL_DISABLED_ADMIN } from '../../../constants/utils';

type CreateUserAdminModalProps = {
  show: boolean,
  contact: { id:number; fullName:string; action: string, userId:any },
  onClose: () => void,
  reloadData: () => void,
};

function CreateUserAdminModal( {
  show, onClose, contact, reloadData,
}: CreateUserAdminModalProps ) {
  const { t } = useTranslation();
  const [formSubmitted, setFormSubmitted] = useState( false );
  const [formSubmittedError, setFormSubmittedError] = useState( '' );

  const submitForm = useSubmit( {
    promise: ( formData ) => ProfileService.putChangeRole( formData ),
    callbackError: ( errors ) => {
      setFormSubmitted( true );
      setFormSubmittedError( errors );
    },
    callback: ( ) => {
      setFormSubmitted( true );
      setFormSubmittedError( '' );
      reloadData();
    },
    showAlert: false,
    deps: [contact],
  } );

  useEffect( () => {
    if ( !show ) setFormSubmitted( false );
  }, [show] );

  let title = 'Activar Admin';
  let description = '¿Desea activar los permisos y privilegios de Administrador de ';
  let formSubmittedDescripcion = t( 'addUserSuccess' );
  if ( contact.action === MODAL_DISABLED_ADMIN ) {
    title = 'Desactivar Admin';
    description = '¿Desea desactivar los permisos y privilegios de Administrador de ';
    formSubmittedDescripcion = t( 'changeUserStatusSuccess' );
  }

  return (
    <Modal
      show={show}
      className="modal-carousel"
      centered
      onHide={onClose}
    >
      <Modal.Header className="pb-0" closeButton />
      <Modal.Body className="pt-0">
        <Row>
          <Col md={11} className="m-auto">
            <h3 className="text-center mb-4">{title}</h3>
            {formSubmitted
              ? (
                <SuccessMessage
                  description={!formSubmittedError ? formSubmittedDescripcion : formSubmittedError}
                  onClose={onClose}
                  success={!formSubmittedError}
                />
              )
              : (
                <div className="text-center">
                  <p className="text--sm">
                    {description}
                    <span className="text--bold">
                      {' '}
                      {contact.fullName}
                      {' '}
                    </span>
                    ?
                  </p>
                  {( contact.action === MODAL_ACTIVE_ADMIN
                      || contact.action === MODAL_DISABLED_ADMIN ) && (
                      <Row>
                        <Col className="m-auto mt-3">
                          <Button
                            variant="outline-primary"
                            className="btn--full-width"
                            onClick={onClose}
                          >
                            {t( 'cancel' )}
                          </Button>
                        </Col>
                        {contact.action === MODAL_DISABLED_ADMIN && (
                        <Col className="m-auto mt-3">
                          <Button
                            variant="danger"
                            className="btn--full-width"
                            onClick={() => submitForm( { userId: contact.userId } )}
                          >
                            {t( 'desactivate' )}
                          </Button>
                        </Col>
                        )}
                        {contact.action === MODAL_ACTIVE_ADMIN && (
                        <Col className="m-auto mt-3">
                          <Button
                            className="btn--full-width"
                            onClick={() => submitForm( { userId: contact.userId } )}
                          >
                            {t( 'activar' )}
                          </Button>
                        </Col>
                        )}
                      </Row>
                  )}
                </div>
              )}
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default CreateUserAdminModal;
