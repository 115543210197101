import Chart from 'chart.js/auto';
import _ from 'lodash';
import numeral from 'numeral';
import { formatCurrency, formatPercent } from './formats';

export const charColors = [
  '#166B8E',
  '#F4963D',
  '#FCD5B0',
  '#C8E7F2',
  '#013854',
  '#62A7A7',
  '#497E7E',
  '#CED72E',
  '#F1F0B0',
  '#B1B1B1',
  '#D6D6D6',
  '#FAB575',
  '#B39252',
  '#B1D3D3',
  '#89BDBD',
  '#166B8E',
  '#F4963D',
  '#FCD5B0',
  '#C8E7F2',
  '#013854',
  '#62A7A7',
  '#497E7E',
  '#CED72E',
  '#F1F0B0',
  '#B1B1B1',
  '#D6D6D6',
  '#FAB575',
  '#B39252',
  '#B1D3D3',
  '#89BDBD',
];

export const charColors2 = [
  '#EA6D1F',
  '#3F7172',
  '#3C3C3B',
  '#E6D6B2',
];

// 'myChart'
export const charProjects = ( {
  labels, data, fullData = [], colors, itemKey, total = 100,
  format = formatCurrency, unit,
}:
{
  labels: string[];
  colors: string[];
  data: number[];
  fullData?: any;
  itemKey: string
  total?: number
  format?: string
  unit?: string
} ) => {
  if ( total > 0 ) {
    return new Chart(
      itemKey,
      {
        type: 'doughnut',
        data: {
          labels,
          datasets: [{
            data,
            backgroundColor: colors,
            hoverBackgroundColor: colors,
            hoverOffset: 4,
            borderWidth: 0,
          }],
        },
        options: {
          plugins: {
            tooltip: {
              backgroundColor: '#FFFFFF',
              bodyColor: '#3C3C3B',
              titleColor: '#999998',
              displayColors: false,
              titleFont: {
                family: 'dmsans',
              },
              bodyFont: {
                family: 'dmsansmedium',
              },
              callbacks: {
                title: ( node ) => node[0].label,
                label: ( node ) => {
                  const currentData: any = _.find( fullData, { label: node.label } );
                  if ( !!currentData && !!currentData?.val ) {
                    return `${numeral( currentData?.val ).format( format )}${unit ? ` ${unit}` : ''}`;
                  }
                  return numeral( node.raw ).format( formatPercent );
                },
              },
            },
            legend: {
              display: false,
            },
          },
          layout: {
            padding: {
              left: 5,
              right: 5,
            },
          },
        },
      },
    );
  }
  return new Chart(
    itemKey,
    {
      type: 'doughnut',
      data: {
        datasets: [{
          data: [100],
          backgroundColor: ['#E3E3E3'],
          hoverBackgroundColor: ['#E3E3E3'],
          hoverOffset: 0,
          borderWidth: 0,
        }],
      },
      options: {
        plugins: {
          tooltip: {
            backgroundColor: '#FFFFFF',
            bodyColor: '#E3E3E3',
            titleColor: '#E3E3E3',
            displayColors: false,
            titleFont: {
              family: 'dmsans',
            },
            bodyFont: {
              family: 'dmsansmedium',
            },
            callbacks: {
              label: ( ) => '0%',
            },
          },
          legend: {
            display: false,
          },
        },
        layout: {
          padding: {
            // left: 5,
            // right: 5,
          },
        },
      },
    },
  );
};

const dataCoorsProvince = [
  {
    province: 'Madrid',
    latitude: 40.4381307,
    longitude: -3.8199671,
  },
  {
    province: 'Baleares',
    latitude: 39.3533717,
    longitude: 1.6118469,
  },
  {
    province: 'Salamanca',
    latitude: 40.963498,
    longitude: -5.7043176,
  },
  {
    province: 'Barcelona',
    latitude: 41.38879,
    longitude: 2.15899,
  },
  {
    province: 'Valencia',
    latitude: 39.46975,
    longitude: -0.37739,
  },
  {
    province: 'Zaragoza',
    latitude: 41.65606,
    longitude: -0.87734,
  },
  {
    province: 'Málaga',
    latitude: 36.72016,
    longitude: -4.42034,
  },
  {
    province: 'Murcia',
    latitude: 37.98704,
    longitude: -1.13004,
  },
];

export const coordsProvince = ( province: string ) => _.find( dataCoorsProvince, { province } );

export const getImageOrigin = ( dataList: any[], key: number ) => {
  let imgUrl = _.get( dataList, `[${key}].base64`, null );
  if ( !imgUrl ) {
    const urlVideo = _.get( dataList, `[${key}].urlVideo`, null );
    if ( urlVideo ) {
      const parts = urlVideo.split( '?v=' );
      imgUrl = parts.length === 2 ? `https://img.youtube.com/vi/${parts[1]}/0.jpg` : null;
    }
  }
  return imgUrl;
};

export const getImagePreview = ( urlVideo: string ) => {
  const parts = urlVideo.split( '?v=' );
  return parts.length === 2 ? `https://img.youtube.com/vi/${parts[1]}/0.jpg` : null;
};
