import React, { useContext } from 'react';
import {
  Navigate,
  Route, Routes,
} from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import './i18n';
import UserLayout from 'screens/User/Layout';
import AdminLayout from 'screens/Admin/Layout';
import Login from 'screens/Login';
import RecoverPassword from 'screens/RecoverPassword';
import { LoadingContext } from 'context/Loading';
import { ModalErrorContext } from 'context/ModalError';
import NotificationModal from 'components/Modal/NotificationModal';
import { selectUser } from 'store/User';
import numeral from 'numeral';
import { useTranslation } from 'react-i18next';
import ForceChangePassword from './screens/ForceChangePassword';

/* type CustomRouteProps = {
  isLoggedIn?: boolean,
  component: ComponentType<any>
} & RouteProps; */

numeral.register( 'locale', 'es', {
  delimiters: {
    thousands: '.',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'K',
    million: 'M',
    billion: 'B',
    trillion: 'T',
  },
  ordinal( number ) {
    return number === 1 ? 'er' : 'ero';
  },
  currency: {
    symbol: '€',
  },
} );
numeral.locale( 'es' );

function PrivateRoute( { children, isLoggedIn }: { children: JSX.Element, isLoggedIn: boolean } ) {
  if ( !isLoggedIn ) {
    return (
      <Navigate
        to="/login"
        replace
      />
    );
  }

  return children;
}

function PublicRoute( { children, isLoggedIn }: { children: JSX.Element, isLoggedIn: boolean } ) {
  if ( isLoggedIn ) {
    return (
      <Navigate
        to="/"
        replace
      />
    );
  }

  return children;
}

function App() {
  const { t } = useTranslation();
  const { loading } = useContext( LoadingContext );
  const { description, showModal, closeErrorModal } = useContext( ModalErrorContext );
  const user = useSelector( selectUser );
  const isLoggedIn = !!( user?.accessToken && user?.account );

  return (
    <>
      <Routes>
        <Route
          path="/login"
          element={(
            <PublicRoute isLoggedIn={isLoggedIn}>
              <Login />
            </PublicRoute>
          )}
        />
        <Route
          path="/recover-password"
          element={(
            <PublicRoute isLoggedIn={isLoggedIn}>
              <RecoverPassword />
            </PublicRoute>
          )}
        />
        <Route
          path="/force-change-password"
          element={(
            <PublicRoute isLoggedIn={isLoggedIn}>
              <ForceChangePassword />
            </PublicRoute>
          )}
        />

        {user?.role === 'Admin' ? (
          <Route
            path="/admin/*"
            element={(
              <PrivateRoute isLoggedIn={isLoggedIn}>
                <AdminLayout />
              </PrivateRoute>
              )}
          />
        ) : null}

        <Route
          path="/*"
          element={(
            <PrivateRoute isLoggedIn={isLoggedIn}>
              <UserLayout />
            </PrivateRoute>
        )}
        />
      </Routes>

      {loading
      && (
        <div className="app-loading">
          <Spinner
            animation="border"
            variant="primary"
            style={{
              width: '3rem',
              height: '3rem',
            }}
          />
        </div>
      )}

      <NotificationModal
        onClose={closeErrorModal}
        description={description}
        title={t( 'hasError' )}
        show={showModal}
        success={false}
      />
    </>
  );
}

export default App;
