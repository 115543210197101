import React, { useCallback, useState } from 'react';
import classnames from 'classnames';
import TextField from 'components/Form/FormTextField';

type PasswordInputProps = {
  field: string;
  label: string;
  classNames?: {
    input?: string;
    formGroup?: string;
    label?: string;
  };
};

function PasswordInput( { field, label, classNames }: PasswordInputProps ) {
  const [showPassword, setShowPassword] = useState( false );

  const toggleShowPassword = useCallback( () => {
    setShowPassword( ( state ) => !state );
  }, [] );

  return (
    <>
      <div className="d-md-block d-none">
        <TextField
          field={field}
          label={label}
          classNames={classNames}
          appendIcon={classnames( 'text--lg', {
            'icon-eye-line': !showPassword,
            'icon-eye-hide-line': showPassword,
          } )}
          type={showPassword ? 'text' : 'password'}
          onClickAppendIcon={toggleShowPassword}
        />
      </div>
      <div className="d-md-none">
        <TextField
          field={field}
          label={label}
          classNames={classNames}
          type="password"
        />
      </div>
    </>
  );
}

export default PasswordInput;
