import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button, Col, Row,
} from 'react-bootstrap';
import Table from 'components/Table';
import TextField from 'components/Form/TextField';
import { DTable } from 'types/table';
import { CellProps } from 'react-table';
import iconDownload from 'assets/img/icons/download.svg';
import numeral from 'numeral';
import { formatCurrencyNoDecimal, formatPercentOneDecimal } from 'utils/formats';
import { useNavigate } from 'react-router-dom';
import { convertToSlug } from 'utils/utils';
import _ from 'lodash';

function Projects( {
  projects, fetchData, isLoading, downloadDocument, type,
}:{
  isLoading?:boolean;
  projects:DTable;
  type?: string;
  downloadDocument: ( params:any )=>void ;
  fetchData: ( params:any, pageSize:number )=>void ; } ) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [criteria, setCriteria] = useState( '' );

  const debounceSearch = useCallback( _.debounce( ( fn ) => fn(), 500 ), [] );

  const columns: any = [
    {
      headerClassName: 'text--left',
      accessor: 'codigoPromocion',
      className: 'text--bold',
      Header: t( 'admin.project.header1' ),
      Cell: ( { cell }: CellProps<any> ) => (
        <span
          className="text--bold cursor-pointer"
          itemID="button1"
          onClick={() => {
            navigate(
              `/admin/projects/${cell.row.original.promocionId}/accounts/pro`,
              { state: { codigoPromocion: cell.row.original.codigoPromocion } },
            );
          }}
        >
          {`${cell.value}`}
        </span>
      ),
    },
    {
      headerClassName: 'text--left',
      accessor: 'estadoVentas',
      Header: t( 'admin.project.header2' ),
      customWidth: 100,
      Cell: ( { cell }: CellProps<any> ) => ( cell.value === 'En Marcha' ? <span className="text--green1">{cell.value}</span>
        : <span className="text--green2">{cell.value}</span> ),
    },
  ];
  if ( type === 'user' ) {
    columns.push( {
      headerClassName: 'text--left',
      accessor: 'nameCuentadeInversion',
      Header: t( 'admin.project.header21' ),
      customWidth: 240,
    } );
  }

  let defaultSortBy = [{ id: 'estadoVentas', desc: true }];
  if ( type === 'inv' ) {
    defaultSortBy = [{ id: 'capitalActualInvertido', desc: true }];
  }
  if ( type === 'user' ) {
    defaultSortBy = [{ id: 'codigoPromocion', desc: true }];
  }
  return (
    <Table
      columns={[...columns,
        {
          headerClassName: 'text--right',
          className: 'text--right text--lato',
          accessor: 'capitalActualInvertido',
          Header: ( type === 'inv' || type === 'pro' || type === 'user' ) ? t( 'admin.project.header31' ) : t( 'admin.project.header3' ),
          customWidth: 180,
          Cell: ( { cell }: CellProps<any> ) => (
            numeral( cell.value ).format( formatCurrencyNoDecimal ) ),
        },
        {
          headerClassName: 'text--right',
          accessor: 'beneficiosEstimados',
          className: 'text--right text--lato',
          Header: `${t( 'admin.project.header4' )}*`,
          customWidth: 180,
          Cell: ( { cell }: CellProps<any> ) => (
            numeral( cell.value ).format( formatCurrencyNoDecimal ) ),
        },
        {
          headerClassName: 'text--right',
          accessor: 'roiEstimado',
          className: 'text--right text--lato',
          Header: `${t( 'admin.project.header5' )}*`,
          customWidth: 120,
          Cell: ( { cell }: CellProps<any> ) => (
            numeral( cell.value / 100 ).format( formatPercentOneDecimal ) ),
        },
        {
          headerClassName: 'text--right',
          accessor: 'tirEstimado',
          Header: `${t( 'admin.project.header6' )}*`,
          className: 'text--right text--lato',
          customWidth: 120,
          Cell: ( { cell }: CellProps<any> ) => (
            numeral( cell.value / 100 ).format( formatPercentOneDecimal ) ),
        },
        {
          headerClassName: 'text--left',
          accessor: 'item6',
          className: 'text--right',
          disableSortBy: true,
          customWidth: 80,
          Cell: ( { cell }:CellProps<any> ) => (
            <Button
              className="p-0"
              variant="link"
              onClick={() => {
                window.open( `/projects/${cell.row.original.promocionId}/${convertToSlug( cell.row.original.codigoPromocion )}`, '_blank' );
              }}
            >
              <i className="icon-eye-line text-primary icon--xs" />
            </Button>
          ),
          Header: t( 'admin.project.header7' ),
        },
      ]}
      isLoading={isLoading}
      data={projects.data}
      total={projects.totalItems}
      pageCount={projects.pageCount}
      fetchData={fetchData}
      defaultSortBy={defaultSortBy}
      specialText2={t( 'successFee' )}
      header={( filters, setFilters ) => (
        <Row className="align-items-center">
          <Col sm={8} xs={6} lg={9}>
            <Button
              size="sm"
              className="pt-2 pb-2 ps-4 pe-4 text--normal btn--rounded d-flex align-items-center"
              onClick={() => downloadDocument( { download: true } )}
            >
              <div>
                <img src={iconDownload} alt="" className="icon--img" />
                {t( 'admin.common.download' )}
              </div>
            </Button>
          </Col>
          <Col sm={4} xs={6} lg={3}>
            <TextField
              value={criteria}
              classNames={{ formGroup: 'input-filters' }}
              placeholder="search"
              appendIcon="icon-search text-primary"
              onChange={( value ) => {
                setCriteria( value );
                debounceSearch( () => setFilters( ['codigoPromocion'], value ) );
              }}
              showErrors={false}
            />
          </Col>
        </Row>
      )}
    />
  );
}

export default Projects;
