import React from 'react';
import {
  Col, Modal, Row,
} from 'react-bootstrap';
import SuccessMessage from '../SuccessMessage';

type CreateUserModalProps = {
  show: boolean,
  title: string,
  success?: boolean,
  description?: string,
  onClose: () => void,
};

function NotificationModal( {
  show, onClose, title, description, success = true,
}: CreateUserModalProps ) {
  return (
    <Modal
      show={show}
      className="modal-carousel"
      centered
      onHide={onClose}
    >
      <Modal.Header className="pb-0" closeButton />
      <Modal.Body className="pt-0">
        <Row>
          <Col md={11} className="m-auto">
            <h4 className="text-center mb-4">{title}</h4>
            <SuccessMessage description={description} onClose={onClose} success={success} />
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default NotificationModal;
