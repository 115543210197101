import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Carousel, Table } from 'react-bootstrap';
import numeral from 'numeral';
import { formatCurrencyNoDecimal } from 'utils/formats';
import _ from 'lodash';
import NoDataText from 'components/NoDataText';
import CardLight from '../../CardLight';

type NextDeliveriesCardProps = {
  dataObjs: { promocionId: any, trimestre: string,
    namePromocion: string,
    repartoCapital: number, repartoBeneficiosEstimados: number }[] | undefined ;
};
function NextDeliveriesCard( {
  dataObjs,
}: NextDeliveriesCardProps ) {
  const { t } = useTranslation();
  const [indexSlide, setIndexSlide] = useState( 0 );
  const [pages, setPages] = useState( 0 );
  const handleSelect = ( selectedIndex: React.SetStateAction<number> ) => {
    setIndexSlide( selectedIndex );
  };
  useEffect( () => {
    if ( !!dataObjs && dataObjs.length ) {
      setPages( Math.ceil( dataObjs.length / 6 ) );
      setIndexSlide( 0 );
    }
  }, [dataObjs] );

  return (
    <CardLight
      title={t( 'dashboard.upcomingDeliveries' )}
      footer={(
        <>
          <div className="text--xxs text--gray8">{t( 'beforeFee' )}</div>
          <div className="text--xxs text--gray8">{t( 'beforeFee2' )}</div>
        </>
)}
      isLoading={!dataObjs}
    >
      {( !!dataObjs && !dataObjs.length ) && <NoDataText text={t( 'dashboard.noUpcomingDeliveriesText' )} /> }
      {( !!dataObjs && dataObjs.length ) ? (
        <Carousel
          activeIndex={indexSlide}
          onSelect={handleSelect}
          controls={false}
          touch
          variant="dark"
          interval={20000}
          className="carousel-dash"
          indicators={dataObjs.length > 6}
        >
          {!!pages && _.map( [...Array( pages )], ( pageContent, pageIndex ) => (
            <Carousel.Item
              style={{ minHeight: '450px' }}
              key={pageIndex}
            >
              <Table borderless className="table--info mb-4">
                <thead>
                  <tr>
                    <th>{t( 'trimester' )}</th>
                    <th className="text--right">{t( 'importe' )}</th>
                  </tr>
                </thead>
                <tbody>
                  {!!dataObjs.length && dataObjs.map( ( item: any, index ) => {
                    if ( index >= pageIndex * 6 && index <= ( pageIndex * 6 ) + 5 ) {
                      return (
                        <tr key={item.promocionId}>
                          <td className="pe-1">
                            <div className="text--sm text--medium">{item.codigoPromocion}</div>
                            <div className="text--sm text--gray8">{item.trimestre}</div>
                          </td>
                          <td className="d-flex justify-content-end no-wrap">
                            <div className="text--lato-bold text--sm">{numeral( Math.round( item.repartoCapital + item.repartoBeneficiosEstimados ) ).format( formatCurrencyNoDecimal )}</div>
                            <span>{item.antesFeeExito ? '*' : '**'}</span>
                            <span className="text-white">{item.antesFeeExito ? '*' : ''}</span>
                          </td>
                        </tr>
                      );
                    }
                    return null;
                  } )}
                </tbody>
              </Table>
            </Carousel.Item>
          ) )}
        </Carousel>
      ) : null }
    </CardLight>
  );
}

export default NextDeliveriesCard;
