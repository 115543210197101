import api from './index';

export default {

  getInvestmentAccounts() {
    return api.get( 'api/cuentadeinversion' );
  },
  getInvestmentGetPersonal( id: number ) {
    return api.get( `api/cuentadeinversion/GetPersonal/${id}` );
  },
  getInvestmentGetEmpresa( id: number ) {
    return api.get( `api/cuentadeinversion/GetEmpresa/${id}` );
  },
  getAllBackOffice( params:any, config:any = {} ) {
    return api.get( 'api/cuentadeinversion/GetAllBackOffice', params, config );
  },
  getAllByPromocionBackOffice( params:any, config:any = {} ) {
    return api.get( 'api/cuentadeinversion/GetAllByPromocionBackOffice', params, config );
  },
};
