import React, { useEffect } from 'react';
import {
  OverlayTrigger, ProgressBar, Table, Tooltip,
} from 'react-bootstrap';
import numeral from 'numeral';
import { formatCurrencyNoDecimal, formatPercent, formatPercentOneDecimal } from 'utils/formats';
import { useTranslation } from 'react-i18next';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import CardLight from '../../CardLight';

am4core.useTheme( am4themes_animated );

type CharProgressProps = {
  dataObjs: any[] | undefined;
  percent?: number;
  title: string;
  itemKey: string;
  label: string;
  val: string;
  formatVal: any;
  id?: any;
  headers: any[];
};

const CharProgress = React.memo( ( {
  dataObjs, title, label, val, formatVal, id, headers, itemKey, percent,
}: CharProgressProps ) => {
  const { t } = useTranslation();
  useEffect( () => {
    const chart = am4core.create( itemKey, am4charts.PieChart );
    chart.innerRadius = am4core.percent( 40 );

    const pieSeries = chart.series.push( new am4charts.PieSeries() );
    pieSeries.dataFields.value = 'value';
    pieSeries.dataFields.category = 'category';
    pieSeries.slices.template.propertyFields.fill = 'fill';
    // @ts-ignore
    pieSeries.slices.template.states.getKey( 'active' ).properties.shiftRadius = 0;
    pieSeries.slices.template.tooltipHTML = '<div style="color: #999998;font-family: dmsans">{category}</div><div style="color: #3C3C3B;font-family: dmsansmedium">{value}</div>';
    pieSeries.innerRadius = 5;
    pieSeries.labels.template.disabled = true;
    pieSeries.ticks.template.disabled = true;
    // @ts-ignore
    pieSeries.tooltip.disabled = true;

    const total = ( !!percent && percent > 0 ) ? percent : 0;
    const remaining = ( !!percent && percent > 0 ) ? 100 - percent : 0;
    pieSeries.data = [{
      category: 'Total',
      value: total,
      fill: '#166B8E',
    }, {
      category: 'Remaining',
      value: remaining,
      fill: '#E3E3E3',
    }];

    const container = new am4core.Container();
    container.parent = pieSeries;
    container.horizontalCenter = 'middle';
    container.verticalCenter = 'middle';
    // @ts-ignore
    container.width = am4core.percent( 40 ) / Math.sqrt( 2 );
    // @ts-ignore
    container.fill = 'white';

    const label2 = new am4core.Label();
    label2.parent = container;
    // eslint-disable-next-line no-template-curly-in-string
    label2.text = total >= remaining ? '{values.value.high}%' : '{values.value.low}%';
    label2.horizontalCenter = 'middle';
    label2.verticalCenter = 'middle';
    label2.fontSize = 24;
    label2.fontWeight = '700';
    label2.fill = am4core.color( '#166B8E' );
  }, [dataObjs] );

  return (
    <CardLight title={title} isLoading={!dataObjs}>
      <div className="m-auto">
        <div id={itemKey} style={{ height: 220 }} />
      </div>
      <Table borderless className="table--info mt-2">
        <tbody>
          {!!dataObjs && !!dataObjs.length && dataObjs.map( ( item, index ) => (
            <tr key={`${item[id]}-${item[label]}`}>
              {headers[0] && (
              <td className="text-ellipsis w-auto" style={{ maxWidth: 0, paddingRight: 10 }}>
                <OverlayTrigger
                  placement="top"
                  overlay={(
                    <Tooltip id={`${item[id]}-${item[label]}`}>
                      {t( item[label] )}
                    </Tooltip>
                  )}
                >
                  <span className="text--medium">
                    {t( item[label] )}
                  </span>
                </OverlayTrigger>
              </td>
              )}
              {headers[1] && (
              <td style={{ width: '100px' }}>
                <div className="mt-2">
                  <ProgressBar
                    now={item[val]}
                    max={100}
                    min={0}
                    className={`progress-bar--c${[index]}`}
                  />
                </div>
              </td>
              )}
              {headers[2] && (
              <td className="text--right" style={{ width: '80px' }}>
                {formatVal === formatPercentOneDecimal && (
                <div className="text--lato-bold">{numeral( item[val] > 0 ? item[val] / 100 : 0 ).format( formatVal )}</div>
                )}
                {formatVal === formatCurrencyNoDecimal && (
                  <div className="text--lato-bold">{numeral( item[val] ).format( formatVal )}</div>
                )}
                {formatVal === formatPercent && (
                  <div className="text--lato-bold">{numeral( item[val] > 0 ? item[val] / 100 : 0 ).format( formatVal )}</div>
                )}
              </td>
              )}
            </tr>
          ) )}
        </tbody>
      </Table>
    </CardLight>
  );
} );

export default CharProgress;
