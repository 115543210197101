import React, { ReactNode, useMemo } from 'react';
import {
  Form, InputGroup, FormControl, FloatingLabel,
} from 'react-bootstrap';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { FieldMetaState } from 'react-final-form';
import Select from 'react-select';
import _ from 'lodash';

type SelectProps = {
  value: string;
  label?: string | ReactNode;
  idKey?: string,
  labelKey?: string,
  translateLabel?: boolean;
  placeholder?: string;
  options: any[];
  validateTranslateValues?: any;
  translateOptions?: boolean;
  classNames?: {
    input?: string;
    formGroup?: string;
    label?: string;
  };
  inputOnChange?: ( value: string ) => void
  onChange?: ( value: string ) => void
  showErrors?: boolean;
  isInvalid?: boolean;
  meta?: FieldMetaState<any>;
};

function SelectInput( {
  value,
  label,
  idKey = 'id',
  labelKey = 'name',
  placeholder,
  options,
  translateLabel = true,
  translateOptions,
  validateTranslateValues,
  classNames = {},
  inputOnChange,
  onChange,
  isInvalid,
  showErrors = true,
  meta,
}: SelectProps ) {
  const { t } = useTranslation();

  const formattedOptions = useMemo( () => _.map( options, ( option ) => ( {
    value: _.get( option, idKey ),
    label: translateOptions ? t( _.get( option, labelKey ) ) : _.get( option, labelKey ),
    original: option,
  } ) ), [options] );

  const getLabel = useMemo( () => _.find( options, { [idKey]: value } )?.[labelKey], [value] );
  const renderField = () => (
    <Select
      value={{ value, label: getLabel }}
      placeholder={placeholder ? t( placeholder ) : ''}
      classNamePrefix="custom-select"
      className={classnames( 'custom-select__container form-control', {
        'is-invalid': isInvalid,
        'has-value': !!value,
      } )}
      onChange={( val: any ) => {
        if ( !val || val.value !== value ) {
          const valueVal = val ? val.value : null;
          if ( inputOnChange ) inputOnChange( valueVal );
          if ( onChange ) onChange( valueVal );
        }
      }}
      options={formattedOptions}
    />
  );
  const labelRender = translateLabel && typeof label === 'string' ? t( label ) : label;
  return (
    <Form.Group className={classnames( 'form-group', classNames.formGroup, {
      'is-invalid': isInvalid,
    } )}
    >
      <InputGroup>
        {label
          ? (
            <FloatingLabel
              label={labelRender}
            >
              {renderField()}
            </FloatingLabel>
          )
          : ( renderField() )}
      </InputGroup>

      {showErrors
      && (
        <FormControl.Feedback type="invalid">
          {isInvalid ? t( meta?.error || meta?.submitError, validateTranslateValues ) || ' ' : ''}
        </FormControl.Feedback>
      )}
    </Form.Group>

  );
}

export default SelectInput;
