import React, { useCallback, useContext } from 'react';
import ProjectsView from 'views/Admin/Projects';
import useFetchTableData from 'hooks/useFetchTableData';
import ProjectsService from 'services/Projects';
import { useLocation, useParams } from 'react-router-dom';
import AdminContainer from 'components/Admin/AdminContainer';
import handlePromise from 'utils/Promise';
import { LoadingContext } from 'context/Loading';
import { getFile } from 'utils/downloadBase64';

function Projects() {
  const { id, type } = useParams();
  const location = useLocation();
  const dataLocation:any = location?.state;
  const { toggleLoading } = useContext( LoadingContext );

  const { fetchData, data, dataLoading } = useFetchTableData( {
    promise: ( params ) => {
      if ( type === 'inv' && id ) {
        return ProjectsService.getAllBackOffice( { ...params, cuentaDeInversionId: id } );
      }
      if ( type === 'user' && id ) {
        return ProjectsService.getAllByContactBackOffice( { ...params, contactId: id } );
      }
      return ProjectsService.getAllBackOffice( params );
    },
    deps: [],
  } );

  const downloadDocument = useCallback( async ( params: any ) => {
    toggleLoading( true );
    let downloadDocumentData;
    if ( type === 'inv' && id ) {
      [, , downloadDocumentData] = await handlePromise(
        ProjectsService.getAllBackOffice( { ...params, cuentaDeInversionId: id }, { responseType: 'blob' } ),
      );
    } else
    if ( type === 'user' && id ) {
      [, , downloadDocumentData] = await handlePromise(
        ProjectsService.getAllByContactBackOffice( { ...params, contactId: id }, { responseType: 'blob' } ),
      );
    } else {
      [, , downloadDocumentData] = await handlePromise(
        ProjectsService.getAllBackOffice( { ...params }, { responseType: 'blob' } ),
      );
    }
    setTimeout( () => {
      toggleLoading( false );
    }, 700 );

    getFile( downloadDocumentData, 'proyectos' );
  }, [] );

  let headerTitle = type === 'inv' ? dataLocation?.nameCuentadeInversion : '';
  headerTitle = type === 'user' ? dataLocation?.fullName : headerTitle;
  headerTitle = type === 'pro' ? dataLocation?.codigoPromocion : headerTitle;

  return (
    <AdminContainer
      extraData={dataLocation?.extraData}
      credentialState={dataLocation?.credentialState}
      headerTitle={headerTitle}
      id={id ? Number( id ) : undefined}
      type={type}
    >
      <ProjectsView
        type={type}
        projects={data}
        fetchData={fetchData}
        isLoading={dataLoading}
        downloadDocument={downloadDocument}
      />
    </AdminContainer>
  );
}

export default Projects;
