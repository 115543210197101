import React from 'react';
import { Field } from 'react-final-form';
import classnames from 'classnames';
import { Button, Form } from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import userImg from 'assets/img/icons/user_profile.svg';
import { useTranslation } from 'react-i18next';

type FileType = { file: any; fileDecoded: string };

type DropzoneType = {
  field: string;
  classNames?: {
    formGroup?: string;
  };
  validate?: any;
  onChange: ( file: FileType ) => void
};

function ImageProfileField( {
  field,
  validate,
  classNames = {},
  onChange,
}: DropzoneType ) {
  const { t } = useTranslation();

  return (
    <Field name={field} validate={validate}>
      {( { input } ) => (
        <Form.Group className={classnames( classNames.formGroup )}>
          <Dropzone
            accept="image/*"
            onDrop={( files ) => {
              if ( files && files[0] ) {
                const reader = new FileReader();
                const file = files[0];
                reader.readAsDataURL( file );
                reader.onload = ( event ) => {
                  const fileValue = { file, fileDecoded: event.target?.result as string };
                  input.onChange( fileValue );
                  onChange( fileValue );
                };
              }
            }}
          >
            {( { getRootProps, getInputProps } ) => (
              <div className="dropzone" {...getRootProps()}>
                <input {...getInputProps()} />

                <div className="dz__message">
                  {input.value
                    ? (
                      <img
                        className="dz__preview"
                        src={input.value && ( input.value as FileType ).fileDecoded
                          ? ( input.value as FileType ).fileDecoded : `${input.value as string}`}
                        alt=""
                      />
                    ) : (
                      <div className="dz__avatar">
                        <img src={userImg} alt="..." />
                      </div>
                    )}
                  <Button variant="link" size="sm" className="text--medium text-decoration-underline">
                    {t( 'changeImageProfile' )}
                  </Button>
                </div>
              </div>
            )}
          </Dropzone>
        </Form.Group>
      )}
    </Field>
  );
}

export default ImageProfileField;
