import React from 'react';
import BodyContent from 'components/BodyContent';
import {
  Button, Col, Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import img2 from 'assets/img/news2.png';
import img3 from 'assets/img/newsFull.png';
import CardLight from 'components/CardLight';

function BlockDetail() {
  return (
    <Row xs={12} md={12} lg={12} className="mb-2 align-items-center mt-3">
      <Col xs="auto">
        <img src={img2} alt="..." className="avatar--xs" />
      </Col>
      <Col xs className="p-0">
        <p className="m-0">Finibus Bonorum et Malorum</p>
        <p className="text--gray8 m-0 text--sm img-fluid">10/10/2001</p>
      </Col>
    </Row>
  );
}

function NewsDetail() {
  const { t } = useTranslation();
  return (
    <BodyContent
      classNames={{ body: 'pt-lg-4' }}
      title={(
        <div className="px-3 pt-3 pb-2">
          <Button variant="link" size="sm" className="text--medium p-0">
            <i className="icon-search text--green1" />
            Volver
          </Button>
        </div>
      )}
    >
      <div className="d-none d-md-block">
        <Button variant="link" size="sm" className="text--medium p-0 mb-2">
          <i className="icon-search text--green1" />
          Volver
        </Button>
      </div>
      <Row>
        <Col xs={12} md={12} lg={8}>
          <img src={img3} alt="..." className="w-100" />
          <Row className="mt-4 align-items-center">
            <Col xs><h1 className="text--xlg text--medium">Finibus Bonorum et Malorum</h1></Col>
            <Col xs="auto"><i className="icon-search text-warning icon--xs" /></Col>
          </Row>
          <Row className="mt-3 align-items-center">
            <Col xs><p className="text--gray8 text--sm mb-0">Written by John doe</p></Col>
            <Col xs="auto"><p className="text--gray8 text--sm mb-0">20 de Marzo de 2021</p></Col>
          </Row>
          <hr className="bg-white" />
          <p>
            Section 1.10.32 of de Finibus Bonorum et Malorum, written by Cicero in 45 BC
            Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque
            laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi
            architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas
            sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione
            voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit
            amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut
            labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis
            nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea
            commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit
            esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas
            nulla pariatur?
          </p>
        </Col>
        <Col xs={12} md={12} lg={4}>
          <CardLight
            title={t( 'news.LastNewsTitle' )}
            fullHeight={false}
            footer={(
              <div className="text-center border-top border-top--gray4">
                <Button variant="link" size="sm" className="text--medium text-decoration-underline">Cargar más</Button>
              </div>
            )}
          >
            <BlockDetail />
            <BlockDetail />
            <BlockDetail />
          </CardLight>
        </Col>
      </Row>
    </BodyContent>
  );
}

export default NewsDetail;
