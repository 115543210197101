import React from 'react';
import BodyContent from 'components/BodyContent';
import {
  Button, Card, Col, Row,
} from 'react-bootstrap';
import img from 'assets/img/news.png';
import img2 from 'assets/img/news2.png';

function CardImg() {
  return (
    <Card className="card-img">
      <Card.Img variant="top" src={img} />
      <Card.Body>
        <Card.Title className="text--medium">
          Long established
        </Card.Title>
        <Card.Text>
          It is a long established fact that a reader will be distracted by the readable
          content of...
        </Card.Text>
      </Card.Body>
      <Card.Footer className="pb-3">
        <Row className="g-0">
          <Col md>
            <span className="text--xs text--gray8 text--bold text-uppercase">
              20 de Marzo de 2021
            </span>
          </Col>
          <Col md="auto">
            <Button variant="link" size="sm" className="text--medium text-decoration-underline p-0">
              Leer más
            </Button>
          </Col>
        </Row>
      </Card.Footer>
    </Card>
  );
}

function News() {
  return (
    <BodyContent title={(
      <div className="px-3 pt-3 pb-2">
        <h1>Noticias</h1>
      </div>
  )}
    >
      <Card className="card-img mb-3 mb-lg-5 d-none d-md-block">
        <div className="row g-4">
          <div className="col-lg-8 col-md-7">
            <Card.Body className="px-5 pt-5">
              <h5 className="card-title">Long established</h5>
              <p className="card-text">
                It is a long established fact that a reader will be distracted by the readable
                content of a page when looking at its layout. The point of using Lorem Ipsum
                is that....
              </p>
            </Card.Body>

            <Card.Footer className="px-5 pb-5">
              <Row className="g-0">
                <Col md>
                  <span className="text--xs text--gray8 text--bold text-uppercase">
                    20 de Marzo de 2021
                  </span>
                </Col>
                <Col md="auto">
                  <Button variant="link" size="sm" className="text--medium text-decoration-underline p-0">
                    Leer más
                  </Button>
                </Col>
              </Row>
            </Card.Footer>
          </div>
          <div className="col-lg-4 col-md-5 text--right">
            <img src={img2} alt="..." className="w-100 h-100" />
          </div>
        </div>
      </Card>
      <div className="d-md-none mb-3">
        <CardImg />
      </div>

      <Row className="g-lg-5">
        <Col xs md={6} lg={4} className="mb-3 mb-md-0">
          <CardImg />
        </Col>
        <Col xs md={6} lg={4} className="mb-3 mb-lg-0">
          <CardImg />
        </Col>
        <Col xs md={6} lg={4} className="mb-3 mb-lg-0">
          <CardImg />
        </Col>
      </Row>
    </BodyContent>
  );
}

export default News;
