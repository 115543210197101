import React, { useCallback, useEffect, useState } from 'react';
import Header from 'components/User/Header';
import TabsHeader from 'components/User/TabsHeader';
import { Container, Spinner } from 'react-bootstrap';
import {
  Route, Routes, useNavigate, useLocation,
} from 'react-router-dom';
import {
  DASHBOARD, HISTORIC, PROJECTS, NEWS, PROFILE, NEWS_DETAIL, ACTIVE_PROJECTS, FUNDS,
  NEWS_DETAIL_ID, FOUND_PROJECTS,
} from 'constants/routes';
import Dashboard from 'screens/User/Dashboard';
import Project from 'screens/User/Project';
import Funds from 'screens/User/Funds';
import Historic from 'screens/User/Historic';
import NewsDashboard from 'screens/User/News/Dashboard';
import NewsDetail from 'screens/User/News/Detail';
import Profile from 'screens/User/Profile';
import handlePromise from 'utils/Promise';
import InvestmentAccountService from 'services/InvestmentAccount';
import ProjectsService from 'services/Projects';
import _ from 'lodash';
import ProfileService from 'services/Profile';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserAccount, updateUserAccount } from 'store/User';

import ActiveProjects from 'screens/User/ActiveProjects';
import FoundProject from '../FoundProject';

function UserLayout() {
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const location = useLocation();
  const [accounts, setAccounts] = useState<any[]>( [] );
  const [projects, setProjects] = useState<any[]>( [] );
  const [projectsSummary, setProjectsSummary] = useState<any[]>( [] );
  const [profile, setProfile] = useState<any>();
  const [readyLayout, setReadyLayout] = useState<boolean>( false );
  const userAccountId = useSelector( selectUserAccount );
  const dispatch = useDispatch();

  const getProfileData = useCallback( async () => {
    const [, profileResponse, profileData] = await handlePromise( ProfileService.getProfile() );
    if ( profileData?.isNew ) {
      navigate( '/force-change-password' );
      return;
    }
    if ( profileResponse.ok ) setProfile( profileData );
  }, [] );

  const getAccount = useCallback( async () => {
    // GET ACCOUNTS
    const [, accountsResponse, accountsData] = await handlePromise(
      InvestmentAccountService.getInvestmentAccounts(),
    );
    if ( accountsResponse.ok ) {
      const accountsList = _.sortBy( accountsData, 'nameCuentadeInversion' );
      setAccounts( accountsList );

      if ( ( !userAccountId || userAccountId === 'admin' ) && accountsList.length ) {
        dispatch( updateUserAccount( accountsList[0].cuentaDeInversionId ) );
      }

      // GET PROJECTS
      const [, projectsResponse, projectsData] = await handlePromise(
        ProjectsService.getPromociones( userAccountId, { tipoDeProyecto: 'Promoción' } ),
      );
      if ( projectsResponse.ok ) setProjects( projectsData?.promocionesActivos );

      /* // GET FUNDS
      const [, fundsResponse, fundsData] = await handlePromise(
        ProjectsService.getPromociones( userAccountId, { tipoDeProyecto: 'Fondo' } ),
      );
      if ( fundsResponse.ok ) setFunds( fundsData?.promocionesActivos ); */

      // GET ALL
      const [, summaryResponse, summaryData] = await handlePromise(
        ProjectsService.getAccountProjectsSummary( userAccountId ),
      );
      if ( summaryResponse.ok ) {
        const orderedData = _.chain( summaryData )
          .filter( ( item ) => item.tipoDeProyecto !== 'Proyecto de un fondo' )
          .orderBy( 'codigoPromocion', 'asc' )
          .value();
        setProjectsSummary( orderedData );
      }

      if ( summaryData?.length ) {
        setReadyLayout( true );
      } else {
        setTimeout( () => {
          setReadyLayout( true );
        }, 800 );
      }
    }
  }, [userAccountId] );

  useEffect( () => {
    getProfileData();
    getAccount();
  }, [userAccountId] );

  return (
    <>
      <Header accounts={accounts} profile={profile} projects={projects} />
      <TabsHeader
        projects={projectsSummary}
      />

      <Container fluid="xl" className="body-container p-0">
        {readyLayout ? (
          <Routes>
            <Route path={HISTORIC} element={<Historic />} />
            <Route path={NEWS} element={<NewsDashboard />} />
            <Route path={NEWS_DETAIL_ID} element={<NewsDetail />} />
            <Route path={NEWS_DETAIL} element={<NewsDetail />} />
            <Route path={`${PROJECTS}/:id/:slug`} element={<Project />} />
            <Route path={`${FOUND_PROJECTS}/:promocionId/:id/:slug`} element={<FoundProject />} />
            <Route path={`${FUNDS}/:id/:slug`} element={<Funds />} />
            <Route path={PROFILE} element={<Profile />} />
            <Route path={ACTIVE_PROJECTS} element={<ActiveProjects />} />
            <Route path={DASHBOARD} element={<Dashboard />} />
            {/* <Redirect from="*" to="/" /> */}
          </Routes>
        )
          : (
            <div className="app-loading">
              <Spinner
                animation="border"
                variant="primary"
                style={{
                  width: '3rem',
                  height: '3rem',
                }}
              />
            </div>
          )}

      </Container>
    </>
  );
}

export default UserLayout;
