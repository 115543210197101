import React, { ElementType, ReactNode, useState } from 'react';
import {
  Form, InputGroup, FormControl, FloatingLabel,
} from 'react-bootstrap';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { FieldMetaState } from 'react-final-form';

type TextFieldProps = {
  value: string;
  label?: string | ReactNode;
  translateLabel?: boolean;
  placeholder?: string;
  rows?: number;
  as?: ElementType<any> | undefined;
  type?: string;
  size?: string;
  validateTranslateValues?: any;
  style?: any;
  appendIcon?: string;
  classNames?: {
    input?: string;
    formGroup?: string;
    label?: string;
  };
  inputOnChange?: ( value: string ) => void
  onChange?: ( value: string ) => void
  showErrors?: boolean;
  isInvalid?: boolean;
  disabled?: boolean;
  meta?: FieldMetaState<any>;
  onClickAppendIcon?: () => void
};

function TextField( {
  value,
  as,
  label,
  placeholder,
  rows,
  type,
  size,
  translateLabel = true,
  validateTranslateValues,
  classNames = {},
  inputOnChange,
  onChange,
  isInvalid,
  showErrors = true,
  appendIcon,
  meta,
  onClickAppendIcon,
  disabled,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  style,
}: TextFieldProps ) {
  const { t } = useTranslation();
  const [focused, setFocused] = useState( false );

  return (
    <Form.Group className={classnames( 'form-group', classNames.formGroup, {
      'is-invalid': isInvalid,
    } )}
    >
      <InputGroup>
        {label
          ? (
            <FloatingLabel
              label={translateLabel && typeof label === 'string' ? t( label ) : label}
            >
              <Form.Control
                value={value || ''}
                placeholder={placeholder ? t( placeholder ) : ' '}
                type={type}
                as={as}
                rows={rows}
                size={size === 'sm' ? 'sm' : undefined}
                isInvalid={isInvalid}
                className={classNames.input}
                onChange={( e ) => {
                  const val = e.target.value;
                  if ( onChange ) onChange( val );
                  if ( inputOnChange ) inputOnChange( val );
                }}
                disabled={disabled}
                style={style}
                onFocus={() => setFocused( true )}
                onBlur={() => setFocused( false )}
              />
            </FloatingLabel>
          )
          : (
            <Form.Control
              value={value || ''}
              placeholder={placeholder ? t( placeholder ) : ' '}
              type={type}
              rows={rows}
              as={as}
              size={size === 'sm' ? 'sm' : undefined}
              isInvalid={isInvalid}
              className={classNames.input}
              onChange={( e ) => {
                const val = e.target.value;
                if ( onChange ) onChange( val );
                if ( inputOnChange ) inputOnChange( val );
              }}
              disabled={disabled}
              style={style}
              onFocus={() => setFocused( true )}
              onBlur={() => setFocused( false )}
            />
          )}

        {appendIcon && (
        <InputGroup.Text
          className={classnames( appendIcon, 'cursor-pointer', { focused } )}
          onClick={onClickAppendIcon}
        />
        )}
      </InputGroup>

      {showErrors
      && (
        <FormControl.Feedback type="invalid">
          {isInvalid ? t( meta?.error || meta?.submitError, validateTranslateValues ) || ' ' : ''}
        </FormControl.Feedback>
      )}
    </Form.Group>

  );
}

export default TextField;
