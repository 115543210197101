import React, { useCallback, useEffect, useState } from 'react';
import {
  Route, Routes, useNavigate,
} from 'react-router-dom';
import Header from 'components/Admin/Header';
import Accounts from 'screens/Admin/Accounts';
import Investors from 'screens/Admin/Investors';
import Projects from 'screens/Admin/Projects';
import {
  ROUTE_ADMIN_ACCOUNT_INVESTORS,
  ROUTE_ADMIN_ACCOUNT_PROJECTS,
  ROUTE_ADMIN_ACCOUNTS,
  ROUTE_ADMIN_INVESTOR_ACCOUNTS, ROUTE_ADMIN_INVESTOR_CHANGE_PASSWORD,
  ROUTE_ADMIN_INVESTOR_PROJECTS,
  ROUTE_ADMIN_INVESTORS, ROUTE_ADMIN_PROFILE,
  ROUTE_ADMIN_PROJECT_ACCOUNTS, ROUTE_ADMIN_PROJECT_INVESTORS,
  ROUTE_ADMIN_PROJECTS,
} from 'constants/routes';
import handlePromise from 'utils/Promise';
import ProfileService from 'services/Profile';
import { Spinner } from 'react-bootstrap';

import ChangePassword from '../Investors/ChangePassword/ChangePassword';
import Profile from '../../User/Profile';

function AdminLayout() {
  const [profile, setProfile] = useState<any>();
  const navigate = useNavigate();
  const [readyLayout, setReadyLayout] = useState<boolean>( false );
  const getProfile = useCallback( async () => {
    const [, profileResponse, profileData] = await handlePromise( ProfileService.getProfile() );
    if ( profileData.isNew ) {
      navigate( '/force-change-password' );
      return;
    }
    if ( profileResponse.ok ) setProfile( profileData );
    setTimeout( () => {
      setReadyLayout( true );
    }, 800 );
  }, [] );

  useEffect( () => {
    getProfile();
  }, [] );

  return (
    <>
      <Header profile={profile} />
      {readyLayout ? (
        <Routes>
          <Route path={ROUTE_ADMIN_ACCOUNT_PROJECTS} element={<Projects />} />
          <Route path={ROUTE_ADMIN_ACCOUNT_INVESTORS} element={<Investors />} />
          <Route path={ROUTE_ADMIN_ACCOUNTS} element={<Accounts />} />
          <Route path={ROUTE_ADMIN_INVESTOR_ACCOUNTS} element={<Accounts />} />
          <Route path={ROUTE_ADMIN_INVESTOR_PROJECTS} element={<Projects />} />
          <Route path={ROUTE_ADMIN_INVESTOR_CHANGE_PASSWORD} element={<ChangePassword />} />
          <Route path={ROUTE_ADMIN_INVESTORS} element={<Investors />} />
          <Route path={ROUTE_ADMIN_PROJECT_ACCOUNTS} element={<Accounts />} />
          <Route path={ROUTE_ADMIN_PROJECT_INVESTORS} element={<Investors />} />
          <Route path={ROUTE_ADMIN_PROFILE} element={<Profile />} />
          <Route path={ROUTE_ADMIN_PROJECTS} element={<Projects />} />
          {/* <Route path="*">
            <Redirect to="/" />
          </Route> */}

        </Routes>
      )
        : (
          <div className="app-loading">
            <Spinner
              animation="border"
              variant="primary"
              style={{
                width: '3rem',
                height: '3rem',
              }}
            />
          </div>
        )}
    </>
  );
}

export default AdminLayout;
