import api from './index';

const basePath = 'api/promocion';

export default {
  // ACCOUNTS
  getKpiHistorical( inversionAccountId: any ) {
    return api.get( `${basePath}/GetKPIHistoricoByCuentaInversion/${inversionAccountId}` );
  },
  getHistorical( inversionAccountId: any, params: any ) {
    return api.get( `${basePath}/GetProyectosCulminados/${inversionAccountId}`, params );
  },
  getKPIPosicionGlobalByCuentaInversion( inversionAccountId: any ) {
    return api.get( `${basePath}/GetKPIPosicionGlobalByCuentaInversion/${inversionAccountId}` );
  },
  getPromociones( inversionAccountId: any, params?: any ) {
    return api.get( `${basePath}/${inversionAccountId}`, params );
  },
  getPromocionesInvertidas( inversionAccountId: any ) {
    return api.get( `${basePath}/GetPromocionesInvertidas/${inversionAccountId}` );
  },
  getPromocionesActivos( inversionAccountId: any, params: any ) {
    return api.get( `${basePath}/GetPromocionActivos/${inversionAccountId}`, params );
  },
  getAccountProjectsSummary( inversionAccountId: any ) {
    return api.get( `${basePath}/GetSummaryInfoByCuentaInversion/${inversionAccountId}` );
  },
  getProximosRepartos( inversionAccountId: any ) {
    return api.get( `${basePath}/GetProximosRepartos/${inversionAccountId}` );
  },
  getMiFlujoCajaEstimado( inversionAccountId: any ) {
    return api.get( `${basePath}/GetMiFlujoCajaEstimado/${inversionAccountId}` );
  },
  getProvinciasMapa( inversionAccountId: any ) {
    return api.get( `${basePath}/GetProvinciasMapa/${inversionAccountId}` );
  },
  getUltimosInformesFinancieros( inversionAccountId: any ) {
    return api.get( `${basePath}/GetUltimosInformesFinancieros/${inversionAccountId}` );
  },
  getDetalleMapaByProvincia( inversionAccountId: any, province: any ) {
    return api.get( `${basePath}/GetDetalleMapaByProvincia/${inversionAccountId}/${province}` );
  },
  // PROJECTS
  getKPIPromocionById( inversionAccountId: any, projectId: any ) {
    return api.get( `${basePath}/GetKPIPromocionById/${inversionAccountId}/${projectId}` );
  },
  getPromocionById( inversionAccountId: any, projectId: any ) {
    return api.get( `${basePath}/GetById/${inversionAccountId}/${projectId}` );
  },
  getDistribucionUsosSuperficies( inversionAccountId: any, projectId: any ) {
    return api.get( `${basePath}/GetDistribucionUsosSuperficies/${inversionAccountId}/${projectId}` );
  },
  getFuentesDeIngreso( inversionAccountId: any, projectId: any ) {
    return api.get( `${basePath}/GetFuentesDeIngreso/${inversionAccountId}/${projectId}` );
  },
  getBalance( inversionAccountId: any, projectId: any ) {
    return api.get( `${basePath}/GetBalance/${inversionAccountId}/${projectId}` );
  },
  getProyectsByFondoId( inversionAccountId: any, projectId: any, params: any ) {
    return api.get( `${basePath}/GetProyectsByFondoId/${inversionAccountId}/${projectId}`, params );
  },
  getPromocionFlujoCajaEstimado( inversionAccountId: any, projectId: any, params?: any ) {
    return api.get( `${basePath}/GetPromocionFlujoCajaEstimado/${inversionAccountId}/${projectId}`, params );
  },
  getAvanceObra( inversionAccountId: any, projectId: any ) {
    return api.get( `${basePath}/GetAvanceObra/${inversionAccountId}/${projectId}` );
  },
  getResumenComercializacion( inversionAccountId: any, projectId: any ) {
    return api.get( `${basePath}/GetResumenComercializacion/${inversionAccountId}/${projectId}` );
  },
  getTiposFinanciacion( inversionAccountId: any, projectId: any ) {
    return api.get( `${basePath}/GetTiposFinanciacion/${inversionAccountId}/${projectId}` );
  },
  getDocumentacion( inversionAccountId: any, projectId: any ) {
    return api.get( `${basePath}/GetDocumentacion/${inversionAccountId}/${projectId}` );
  },
  getDocumentacionInversion( inversionAccountId: any, projectId: any ) {
    return api.get( `${basePath}/GetDocInversionPromocion/${inversionAccountId}/${projectId}` );
  },
  getAvanceFotograficoPeriodos( inversionAccountId: any, projectId: any ) {
    return api.get( `${basePath}/GetAvanceFotograficoPeriodos/${inversionAccountId}/${projectId}` );
  },
  getAvanceFotografico( inversionAccountId: any, projectId: any, keyParam: any ) {
    return api.get( `${basePath}/GetAvanceFotografico/${inversionAccountId}/${projectId}/${keyParam}` );
  },
  getDownloadFile( params: any ) {
    return api.get( `${basePath}/GetDownloadFile`, params );
  },
  getUltimoInformeFinancieroPromocion( inversionAccountId: any, projectId: any, config?: any ) {
    return api.get( `${basePath}/GetUltimoInformeFinancieroPromocion/${inversionAccountId}/${projectId}`, {}, config );
  },
  getUltimoInformeMovementsPromocion( inversionAccountId: any, projectId: any, config?: any ) {
    return api.get( `${basePath}/GetInformePdfByPromocion/${inversionAccountId}/${projectId}`, {}, config );
  },
  getInformePdfByCuentaInversion( inversionAccountId: any, config: any ) {
    return api.get( `${basePath}/GetInformePdfByCuentaInversion/${inversionAccountId}`, {}, config );
  },
  getPdfResumenProyectos( inversionAccountId: any, config: any ) {
    return api.get( `${basePath}/GetPdfResumenProyectos/${inversionAccountId}`, {}, config );
  },
  getInformePdfByPromocion( inversionAccountId: any, projectId: any, config: any ) {
    return api.get( `${basePath}/GetInformePdfByPromocion/${inversionAccountId}/${projectId}`, {}, config );
  },
  getCertificadoTitularidadPdfById( inversionAccountId: any, projectId: any, config: any ) {
    return api.get( `${basePath}/GetCertificadoTitularidadPdfById/${inversionAccountId}/${projectId}`, {}, config );
  },
  // BACKOFFICE
  getAllBackOffice( params: any, config: any = {} ) {
    return api.get( `${basePath}/GetAllBackOffice`, params, config );
  },
  getAllByContactBackOffice( params: any, config: any = {} ) {
    return api.get( `${basePath}/GetAllByContactBackOffice`, params, config );
  },
  getHistoricoPdfByCuentaInversion( inversionAccountId: any, config: any = {} ) {
    return api.get( `${basePath}/GetHistoricoPdfByCuentaInversion/${inversionAccountId}`, {}, config );
  },

  //

  getProyectoFondoById( inversionAccountId: any, promocionId: any, projectId: any ) {
    return api.get( `api/proyectofondo/GetById/${inversionAccountId}/${promocionId}/${projectId}` );
  },
  getKPIProyectoById( inversionAccountId: any, promocionId: any, projectId: any ) {
    return api.get( `api/proyectofondo/GetKPIProyectoById/${inversionAccountId}/${promocionId}/${projectId}` );
  },
  getAvanceProyecto( inversionAccountId: any, promocionId: any, projectId: any ) {
    return api.get( `api/proyectofondo/GetAvanceProyecto/${inversionAccountId}/${promocionId}/${projectId}` );
  },
  getIngresosTotales( inversionAccountId: any, promocionId: any, projectId: any ) {
    return api.get( `api/proyectofondo/GetIngresosTotales/${inversionAccountId}/${promocionId}/${projectId}` );
  },
  getEstructuraInversion( inversionAccountId: any, promocionId: any, projectId: any ) {
    return api.get( `api/proyectofondo/GetEstructuraInversion/${inversionAccountId}/${promocionId}/${projectId}` );
  },
  getProjectFundDocumentacion( inversionAccountId: any, promocionId: any, projectId: any ) {
    return api.get( `api/proyectofondo/GetDocumentacion/${inversionAccountId}/${promocionId}/${projectId}` );
  },
  getProjectFundAvanceFotografico( inversionAccountId: any, promocionId: any, projectId: any ) {
    return api.get( `api/proyectofondo/GetAvanceFotograficoPeriodos/${inversionAccountId}/${promocionId}/${projectId}` );
  },
};
