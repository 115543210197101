import React, { useCallback, useState } from 'react';
import {
  Row, Col, Button, Accordion,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CardLight from 'components/CardLight';
import TextField from 'components/Form/FormTextField';
import ImageProfileField from 'components/ImageProfile';
import Form from 'components/Form/Layout';
import { Profile as ProfileType } from 'types/User';
import NotificationModal from 'components/Modal/NotificationModal';
import useSubmit from 'hooks/useSubmit';
import ProfileService from 'services/Profile';
import { FiMail } from 'react-icons/fi';
import Documents from 'components/Dashboard/Documents';

function Title( { onOpenContactModal }: Partial<ProfileProps> ) {
  const { t } = useTranslation();

  function ContactBtn() {
    return (
      <Button className="btn--xs btn--rounded" onClick={onOpenContactModal}>
        <FiMail className="icon--xs m-1 me-2" />
        {t( 'contact' )}
      </Button>
    );
  }

  return (
    <div className="px-3 pt-3 pb-4 pb-md-0">
      <Row className="align-items-end">
        <Col>
          <Row>
            <Col>
              <h1 className="mb-2">{t( 'profile.title' )}</h1>
            </Col>
            <Col xs="auto" className="d-md-none">
              <ContactBtn />
            </Col>
          </Row>
          <p className="mb-0">
            {t( 'profile.subtitle' )}
          </p>
        </Col>
        <Col md="auto" className="d-none d-md-block">
          <ContactBtn />
        </Col>
      </Row>
    </div>
  );
}

function BlockField( { field1, field2 }:{ field1: string, field2?: string } ) {
  return (
    <Row>
      <Col md={6} className="mb-2">
        <TextField
          size="sm"
          field={field1}
          label={field1}
          disabled
        />
      </Col>
      <Col md={6} className="mb-2">
        {!!field2 && (
        <TextField
          size="sm"
          field={field2}
          label={field2}
          disabled
        />
        )}

      </Col>
    </Row>
  );
}

type ProfileProps = {
  onOpenContactModal: ()=>void
  onOpenChangePasswordModal: ()=>void
  accounts:any[]; profile?:ProfileType
};

function Profile( {
  onOpenContactModal, onOpenChangePasswordModal, accounts, profile,
}: ProfileProps ) {
  const { t } = useTranslation();
  const [showModal, setModal] = useState( false );
  const [, setFormSubmitted] = useState( false );
  const [formSubmittedError, setFormSubmittedError] = useState( '' );

  const toggleModal = useCallback( () => {
    setModal( ( state ) => !state );
  }, [] );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const submitSimpleForm = useCallback( ( values: any ) => {
    // console.log( 'values', values );
  }, [] );

  const submitForm = useSubmit( {
    promise: ( formData ) => ProfileService.putUploadImage( formData ),
    callbackError: ( errors ) => {
      setFormSubmitted( true );
      if ( typeof errors === 'object' ) {
        setFormSubmittedError( errors.title ? errors.title : JSON.stringify( errors ) );
      } else {
        setFormSubmittedError( errors );
      }
      toggleModal();
    },
    callback: ( ) => {
      setFormSubmitted( true );
    },
    showAlert: false,
  } );

  return (
    <>
      <Title onOpenContactModal={onOpenContactModal} />
      <div className="body-content">
        <Row>
          <Col lg className="mb-4 mb-lg-0">
            <div className="mb-4">
              <CardLight fullHeight={false} title={t( 'profile.personalInfo' )}>
                <Form
                  initialValues={profile}
                  onSubmit={submitSimpleForm}
                  body={(
                    <div className="pt-4">
                      <ImageProfileField
                        field="photo"
                        classNames={{
                          formGroup: 'mb-5',
                        }}
                        onChange={( file ) => {
                          const formData = new FormData();
                          formData.append( 'file', file.file );
                          submitForm( formData );
                        }}
                      />

                      <BlockField field1="firstName" field2="lastName" />
                      <BlockField field1="email" field2="mobile" />
                      <BlockField field1="tipoDeDocumento" field2="dninie" />
                      <BlockField field1="nacionalidad" />
                    </div>
                )}
                />
              </CardLight>
            </div>
            <CardLight fullHeight={false} title={t( 'profile.privacy' )}>
              <div className="text-center py-4">
                <Button className="text--md" onClick={onOpenChangePasswordModal}>
                  {t( 'profile.changePassword' )}
                </Button>
              </div>
            </CardLight>
          </Col>
          <Col lg>
            {!!accounts.length && (
              <div className="mb-4">
                <CardLight fullHeight={false} title={t( 'profile.investmentsAccounts' )}>
                  <Accordion>
                    {!!accounts.length && accounts.map( ( item, index ) => (
                      <Accordion.Item
                        key={`${item.cuentaDeInversionId}`}
                        eventKey={`${item.cuentaDeInversionId}-${index}`}
                        className={index > 0 ? 'border-top border-top--gray11' : ''}
                      >
                        <Accordion.Header>
                          <Row className="w-100">
                            <Col>{item.nameCuentadeInversion}</Col>
                            <Col xs="auto">
                              <span className="text--xs text--gray8 text-uppercase">{item.tipoCuenta}</span>
                            </Col>
                          </Row>
                        </Accordion.Header>
                        {item.tipoCuenta === 'Particular' && (
                        <Accordion.Body className="px-0">
                          <Form
                            initialValues={item.detail}
                            onSubmit={submitSimpleForm}
                            body={(
                              <div className="pt-4">
                                <BlockField field1="nombre" field2="apellido" />
                                <BlockField field1="email" field2="telefonoMovil" />
                                <BlockField field1="tipoDocumento" field2="numDocumento" />
                                <BlockField field1="nacionalidad" field2="fechaNacimiento" />
                                <BlockField field1="direccionResidencia" field2="codigoPostal" />
                                <BlockField field1="localidad" field2="provincia" />
                                <BlockField field1="pais" />
                                <Row>
                                  <Col>
                                    <TextField
                                      size="sm"
                                      field="cuentaBancariaAsociada"
                                      label="cuentaBancariaAsociada"
                                      classNames={{ formGroup: 'mb-2' }}
                                      disabled
                                    />
                                  </Col>
                                </Row>
                                <Row className="mt-2">
                                  <Col>
                                    <Col>
                                      <span className="text--medium">{t( 'documentation' )}</span>
                                    </Col>
                                    <Documents
                                      dataObjs={item.sharepointInfoCuentaDocumentacion}
                                      title=""
                                      fullHeight={false}
                                      extraClass="card--base-light"
                                      listGroupClass="bg--grey"
                                    />
                                  </Col>
                                </Row>
                              </div>
                                      )}
                          />
                        </Accordion.Body>
                        )}
                        {item.tipoCuenta === 'Empresa' && (
                        <Accordion.Body className="px-0">
                          <Form
                            initialValues={item.detail}
                            onSubmit={submitSimpleForm}
                            body={(
                              <div className="pt-4">
                                <BlockField field1="razonSocialEmpresa" field2="cifEmpresa" />
                                <BlockField field1="direccionFiscalEmpresa" field2="codigoPostalEmpresa" />
                                <BlockField field1="localidadEmpresa" field2="provinciaEmpresa" />
                                <BlockField field1="representanteLegal" field2="conceptoRepresentante" />
                                <BlockField field1="tipoDocumento" field2="numDocumento" />
                                <BlockField field1="nacionalidad" field2="email" />
                                <Row>
                                  <Col>
                                    <TextField
                                      size="sm"
                                      field="cuentaBancariaAsociada"
                                      label="bankAccount"
                                      classNames={{ formGroup: 'mb-2' }}
                                      disabled
                                    />
                                  </Col>
                                </Row>
                                <Row className="mt-2">
                                  <Col>
                                    <Col>
                                      <span className="text--medium">{t( 'documentation' )}</span>
                                    </Col>
                                    <Documents
                                      dataObjs={item.sharepointInfoCuentaDocumentacion}
                                      title=""
                                      fullHeight={false}
                                      extraClass="card--base-light"
                                      listGroupClass="bg--grey"
                                    />
                                  </Col>
                                </Row>
                              </div>
                                      )}
                          />
                        </Accordion.Body>
                        )}
                      </Accordion.Item>
                    ) )}
                  </Accordion>
                </CardLight>
              </div>
            )}
          </Col>
        </Row>
      </div>
      <NotificationModal
        show={showModal}
        onClose={toggleModal}
        title="Error"
        success={!formSubmittedError}
        description={formSubmittedError}
      />
    </>
  );
}

export default Profile;
