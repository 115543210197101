import React from 'react';
import SelectAccountView from 'views/SelectAccount';
import { Account } from 'types/Account';

type SelectAccountProps = {
  show: boolean,
  onClose: () => void
  selectAccount: ( accountId: string ) => void
  accounts: Account[],
  userRole?: string,
};

function SelectAccount( {
  show, onClose, accounts, userRole, selectAccount,
}: SelectAccountProps ) {
  return (
    <SelectAccountView
      show={show}
      onClose={onClose}
      accounts={accounts}
      role={userRole}
      onSelectAccount={selectAccount}
    />
  );
}

export default SelectAccount;
