import api from './ApiUpdate';

export default {
  updateModuleRefresh() {
    return api.get( 'api/modules/refresh' );
  },
  updateModuleFinance( ) {
    return api.get( 'api/modules/informesfinancieros' );
  },
  updateModuleDoc( ) {
    return api.get( 'api/modules/documentacion' );
  },
  updateModulePhotos( ) {
    return api.get( 'api/modules/avancefotografico' );
  },
  updateModulePhotosKeys( ) {
    return api.get( 'api/modules/avancefotograficokeys' );
  },
};
