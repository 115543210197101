import { configureStore, combineReducers } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';

import user from './User';
import settings from './Settings';

const reducers = combineReducers( {
  user,
  settings,
} );

const persistConfig = {
  key: 'grupoimpar',
  storage,
};

const persistedReducer = persistReducer( persistConfig, reducers );

export const store = configureStore( {
  reducer: persistedReducer,
  middleware: ( getDefaultMiddleware ) => getDefaultMiddleware( {
    serializableCheck: {
      ignoredActions: ['persist/PERSIST'],
    },
  } ),
} );

export const persistor = persistStore( store );
