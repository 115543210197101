import React from 'react';
import {
  Button, Col, Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { IoAlertCircleOutline, IoCheckmarkCircleOutline } from 'react-icons/io5';

type MapModalProps = {
  description?: string,
  success?: boolean,
  onClose: () => void,
};

function SuccessMessage( { description, onClose, success = true }: MapModalProps ) {
  const { t } = useTranslation();
  return (
    <div className="text-center">
      {success ? ( <IoCheckmarkCircleOutline size={34} className="text--green2" /> )
        : ( <IoAlertCircleOutline size={34} className="text-danger" /> )}
      <p className={classNames( 'text--sm mt-2', { 'text--green2': success, 'text-danger': !success } )}>{description}</p>

      <Row>
        <Col className="m-auto mt-3">
          <Button variant="primary" className="btn--full-width" onClick={onClose}>
            {t( 'accept' )}
          </Button>
        </Col>
      </Row>
    </div>
  );
}

export default SuccessMessage;
