import React from 'react';
import './styles/index.scss';
import { Provider } from 'react-redux';
import {
  BrowserRouter,
} from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { persistor, store } from './store';
import * as serviceWorker from './serviceWorker';
import LoadingProvider from './context/Loading';
import ModalErrorProvider from './context/ModalError';

const rootElement = document.getElementById( 'root' );
// @ts-ignore
createRoot( rootElement ).render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <LoadingProvider>
          <ModalErrorProvider>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </ModalErrorProvider>
        </LoadingProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
