import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as am4core from '@amcharts/amcharts4/core';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { Row, Col, Button } from 'react-bootstrap';
import { CashFlowChart } from 'utils/AmChars';
import CardLight from 'components/CardLight';
import CashFlowItemLegend from 'components/Dashboard/CashFlow';
import { HiOutlineDownload } from 'react-icons/hi';

am4core.useTheme( am4themes_animated );
type CashFlowProps = {
  title?: string
  dataObjs: any[] | undefined;
  downloadFile: ( type:'projects' | 'flow' )=>void ;
};
function CashFlow( {
  title, dataObjs, downloadFile,
}: CashFlowProps ) {
  const { t } = useTranslation();

  useEffect( () => {
    if ( process.env.NODE_ENV !== 'test' && !!dataObjs ) CashFlowChart( dataObjs );
  }, [dataObjs] );

  return (
    <CardLight fullHeight={false} isLoading={!dataObjs}>
      <Row className="mb-3 p-0 m-0 pt-2">
        <Col xs={{ span: 10, order: 1 }} className="p-0 m-0">
          <h3 className="text-ellipsis text--xmmd">
            {title || t( 'dashboard.myEstimatedCashFlow' )}
          </h3>
        </Col>
        <Col xs={{ span: 2, order: 2 }} className="text-end p-0 m-0">
          <Button
            variant="link"
            size="sm"
            className="text--medium p-0"
            onClick={async () => {
              await downloadFile( 'flow' );
            }}
          >
            <HiOutlineDownload color="#166B8E" size={22} />
          </Button>
        </Col>
      </Row>
      <div className="">
        <Row className="justify-content-md-end">
          <Col xs="auto">
            <CashFlowItemLegend title={t( 'capitalDistribution' )} className="bg-green legend-chart" />
            <CashFlowItemLegend title={t( 'distributionEstimatedBenefits' )} className="bg-orange legend-chart" />
            <CashFlowItemLegend title={t( 'distributionLlamadaCapital' )} className="bg-orange4 legend-chart" />
          </Col>
          <Col xs="auto">
            <CashFlowItemLegend title={t( 'capitalContribution' )} className="bg-black legend-chart" />
            <CashFlowItemLegend title={t( 'totalBalance' )} className="legend-chart bg-gray" />
          </Col>
        </Row>
      </div>
      <div id="chartDiv3" style={{ height: 400, marginTop: 20 }} />
      <div className="mt-3">
        <div className="text--xxs text--gray8">{t( 'beforeFee' )}</div>
        <div className="text--xxs text--gray8">{t( 'beforeFee2' )}</div>
      </div>
    </CardLight>
  );
}

export default CashFlow;
