import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button, Col, Row,
} from 'react-bootstrap';
import { CellProps } from 'react-table';
import numeral from 'numeral';
import { formatCurrencyNoDecimal, formatPercentOneDecimal } from 'utils/formats';
import { DTable } from 'types/table';
import Table from 'components/Table';
import { HiOutlineDownload } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import { convertToSlug } from 'utils/utils';

type ActiveProjectsProps = {
  isLoading?:boolean;
  activeProjects:DTable;
  fetchData: ( params:any, pageSize:number )=>void ;
  downloadFile: ( type:'projects' | 'flow' )=>void ;
};
function ActiveProjects( {
  activeProjects,
  fetchData,
  isLoading,
  downloadFile,
}: ActiveProjectsProps ) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Table
      columns={[
        {
          Header: t( 'name' ),
          accessor: 'codigoPromocion',
          Cell: ( { cell }:CellProps<any> ) => (
            <Button
              className="p-0"
              variant="link"
              onClick={() => {
                if ( cell.row.original.tipoDeProyecto === 'Fondo' ) {
                  navigate( `/investment-vehicles/${cell.row.original.promocionId}/${convertToSlug( cell.value )}` );
                } else {
                  navigate( `/projects/${cell.row.original.promocionId}/${convertToSlug( cell.value )}` );
                }
              }}
            >
              <span className="text--bold text--normal text--md">{cell.value}</span>
            </Button>
          ),
        },
        {
          className: 'text--lato',
          Header: t( 'start' ),
          accessor: 'inicio',
          customWidth: 100,
        },
        {
          className: 'text--lato',
          Header: t( 'end' ),
          accessor: 'fin',
          customWidth: 100,
        },
        {
          Header: t( 'subscribedCapital' ),
          accessor: 'capitalInvertido',
          className: 'text--right text--lato',
          headerClassName: 'text--right',
          customWidth: 180,
          Cell: ( { cell }: CellProps<any> ) => (
            numeral( cell.value ).format( formatCurrencyNoDecimal ) ),
        },
        {

          Header: `${t( 'estimatedProfit' )}*`,
          headerClassName: 'text--right',
          accessor: 'beneficio',
          className: 'text--right text--lato',
          customWidth: 160,
          Cell: ( { cell }: CellProps<any> ) => (
            numeral( cell.value ).format( formatCurrencyNoDecimal ) ),
        },
        {
          headerClassName: 'text--right',
          Header: `${t( 'roi' )}*`,
          accessor: 'roi',
          className: 'text--right text--lato',
          customWidth: 100,
          Cell: ( { cell }: CellProps<any> ) => (
            numeral( cell.value / 100 ).format( formatPercentOneDecimal ) ),
        },
        {
          headerClassName: 'text--right',
          Header: `${t( 'tir' )}*`,
          accessor: 'tir',
          className: 'text--right text--lato',
          customWidth: 100,
          Cell: ( { cell }: CellProps<any> ) => (
            numeral( cell.value / 100 ).format( formatPercentOneDecimal ) ),
        },
      ]}
      isLoading={isLoading}
      total={activeProjects.totalItems}
      fetchData={fetchData}
      pageCount={activeProjects.pageCount}
      data={activeProjects.data}
      defaultPageSize={5}
      paginationOptions={['5', '10']}
      specialText={t( 'beforeFee' )}
      cardHeader={(
        <Row className="mb-3 p-0 m-0">
          <Col md={{ span: 10, order: 1 }} className="p-0 m-0">
            <h3 className="text-ellipsis text--xmmd">{t( 'dashboard.activeProjects' )}</h3>
          </Col>
          <Col md={{ span: 2, order: 2 }} className="text-end p-0 m-0">
            <Button
              variant="link"
              size="sm"
              className="text--medium p-0"
              onClick={async () => {
                await downloadFile( 'projects' );
              }}
            >
              <HiOutlineDownload color="#166B8E" size={22} />
            </Button>
          </Col>
        </Row>
 )}
    />
  );
}

export default ActiveProjects;
