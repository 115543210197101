import React, { ReactNode } from 'react';
import { Button, Card } from 'react-bootstrap';
import classnames from 'classnames';

type CardLightProps = {
  title?: string;
  extraClass?: string;
  fullHeight?: boolean;
  isLoading?: boolean;
  children: ReactNode;
  footer?: ReactNode;
  onClick?: ()=>void;
};
function CardLight( {
  title, children, footer, onClick, extraClass, fullHeight = true, isLoading = false,
}: CardLightProps ) {
  return (
    <Card className={classnames( 'card--light', extraClass, {
      'h-100': fullHeight,
      'app-component-loading': isLoading,
    } )}
    >
      {title
    && (
      <Card.Header>
        {!onClick
          ? <h3 className="text-ellipsis text--xmmd">{title}</h3>
          : (
            <Button
              className="p-0"
              variant="link"
              onClick={onClick}
            >
              <span className="text--bold text--normal text--xmmd">{title}</span>
            </Button>
          )}

      </Card.Header>
    )}

      <Card.Body>
        {children}
      </Card.Body>

      {footer
    && (
    <Card.Footer>
      {footer}
    </Card.Footer>
    )}
    </Card>
  );
}

export default CardLight;
