import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import DashboardView from 'views/User/Dashboard';
import MapModal from 'components/Modal/MapModal';
import handlePromise from 'utils/Promise';
import ProjectService from 'services/Projects';
import { useSelector } from 'react-redux';
import { selectUserAccount } from 'store/User';
import { KpiAccount } from 'types/Account';
import { LoadingContext } from 'context/Loading';
import useFetchTableData from 'hooks/useFetchTableData';
import { getFile } from 'utils/downloadBase64';

function Dashboard() {
  const { toggleLoading } = useContext( LoadingContext );
  const userAccountId = useSelector( selectUserAccount );
  const [showMapModal, setMapModal] = useState( false );
  const [kpiAccount, setKpiAccount] = useState<KpiAccount | null>( null );
  const [projects, setProjects] = useState<[] | undefined>( );
  const [mapInfo, setMapInfo] = useState<[] | undefined>( );
  const [nextDeliveries, setNextDeliveries] = useState<[] | undefined>( );
  const [myCashFlow, setMyCashFlow] = useState<[] | undefined>( );
  const [latestReports, setLatestReports] = useState<[] | undefined>( );
  const [dataLoaded, setDataLoaded] = useState( false );
  const [mapDetail, setMapDetail] = useState<{
    province: string,
    list: [],
    defaultCoors: {
      lat: number,
      lng: number,
    },
  }>( {
    province: '',
    list: [],
    defaultCoors: {
      lat: 0.0,
      lng: 0.0,
    },
  } );

  const openMapModal = useCallback( async ( { params }:{ params:any } ) => {
    toggleLoading( true );
    const [, mapDetailResponse, mapDetailData] = await handlePromise(
      ProjectService.getDetalleMapaByProvincia( userAccountId, params.province ),
    );
    if ( mapDetailResponse.ok && mapDetailData.length ) {
      const defaultCors = mapDetailData[0];
      setMapDetail( {
        province: params.province,
        defaultCoors: {
          lat: defaultCors.latitud,
          lng: defaultCors.longitud,
        },
        list: mapDetailData,
      } );
      setMapModal( true );
      setTimeout( () => {
        toggleLoading( false );
      }, 800 );
    }
  }, [userAccountId] );

  const closeMapModal = useCallback( ( ) => {
    setMapModal( false );
  }, [] );

  const downloadFile = useCallback( async ( type:'projects' | 'flow' ) => {
    toggleLoading( true );
    let downloadDocumentData;
    if ( type === 'projects' ) {
      [, , downloadDocumentData] = await handlePromise(
        ProjectService.getPdfResumenProyectos( userAccountId, { responseType: 'blob' } ),
      );
    } else {
      [, , downloadDocumentData] = await handlePromise(
        ProjectService.getInformePdfByCuentaInversion( userAccountId, { responseType: 'blob' } ),
      );
    }

    setTimeout( () => {
      toggleLoading( false );
    }, 800 );

    if ( downloadDocumentData ) {
      getFile( downloadDocumentData, type === 'projects' ? 'Resumen-proyectos' : 'Informe-por-cuenta-inversión', 'application/pdf', 'pdf' );
    }
  }, [userAccountId] );

  const { fetchData, data, dataLoading } = useFetchTableData( {
    promise: ( params ) => ProjectService.getPromocionesActivos( userAccountId, params ),
    deps: [userAccountId],
  } );

  const getData = useCallback( async () => {
    setProjects( undefined );
    setMapInfo( undefined );
    setNextDeliveries( undefined );
    setMyCashFlow( undefined );
    setLatestReports( undefined );
    const [, kpiAccountResponse, kpiAccountData] = await handlePromise(
      ProjectService.getKPIPosicionGlobalByCuentaInversion( userAccountId ),
    );
    if ( kpiAccountResponse.ok ) setKpiAccount( kpiAccountData );

    const [, projectsResponse, projectsData] = await handlePromise(
      ProjectService.getPromocionesInvertidas( userAccountId ),
    );
    if ( projectsResponse.ok ) setProjects( projectsData );

    const [, mapDataRespose, mapData] = await handlePromise(
      ProjectService.getProvinciasMapa( userAccountId ),
    );
    if ( mapDataRespose.ok ) setMapInfo( mapData );

    const [, nextResponse, nextData] = await handlePromise(
      ProjectService.getProximosRepartos( userAccountId ),
    );
    if ( nextResponse.ok ) setNextDeliveries( nextData );

    const [, cashFlowResponse, cashFlow] = await handlePromise(
      ProjectService.getMiFlujoCajaEstimado( userAccountId ),
    );
    if ( cashFlowResponse.ok ) setMyCashFlow( cashFlow );

    const [, latestReportsResponse, latestReportsData] = await handlePromise(
      ProjectService.getUltimosInformesFinancieros( userAccountId ),
    );
    if ( latestReportsResponse.ok ) setLatestReports( latestReportsData );

    setTimeout( () => {
      setDataLoaded( true );
    }, 800 );
  }, [userAccountId] );

  useEffect( () => {
    getData();
  }, [userAccountId] );

  return (
    <>
      <DashboardView
        onOpenMapModal={openMapModal}
        kpiAccount={kpiAccount}
        projects={projects}
        mapInfo={mapInfo}
        nextDeliveries={nextDeliveries}
        myCashFlow={myCashFlow}
        latestReports={latestReports}
        dataLoaded={dataLoaded}
        activeProjects={data}
        fetchData={fetchData}
        isLoading={dataLoading}
        downloadFile={downloadFile}
      />
      <MapModal
        show={showMapModal}
        onClose={closeMapModal}
        mapDetail={mapDetail}
      />
    </>
  );
}

export default Dashboard;
